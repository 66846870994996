import styles from './edit-group.module.scss'
import { Button, TextField, AutoComplete, Snackbar } from "components";
import submitIcon from 'assets/images/icon/submit-icon.svg';
import { useEffect, useState, useContext } from 'react';

import AuthServices from 'api/services/auth-services';
import { GlobalContext, actions } from 'context';
import { useHistory } from 'react-router-dom';
import Header from 'app/header/header';

const EditGroup = () => {

    const history = useHistory()
    const id = new URLSearchParams(history.location.search).get("id")
    const { dispatch } = useContext(GlobalContext);
    const [options, setOptions] = useState([])
    const [inputValue, setInputValue] = useState([]);
    const [name, setName] = useState('')
    const [handleplaceholder, setHandlePlaceholder] = useState(true)
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });
    
    useEffect(() => {
        PromiseAll()
    }, [])

    const PromiseAll = async () => {
        storeHandler(actions.SHOW_LOADER, true)
        try {
            const [groupDetail , nhubList] = await Promise.all([AuthServices.detailGroup(id) , AuthServices.getNhubLists()])
            const groupNhub = groupDetail?.data?.nhubs?.map((item) => { return { device_id: item.rpi_id , id: item.id } }) 
            setOptions([ ...groupNhub, ...nhubList.data ])
            setInputValue(groupNhub)
            setName(groupDetail?.data?.group?.group_name)
            storeHandler(actions.SHOW_LOADER, false)
        } catch (err) {
            storeHandler(actions.SHOW_LOADER, false)
        }
    }

    useEffect(() => {
        if (inputValue?.length > 0) {
            setHandlePlaceholder(false)
        } else {
            setHandlePlaceholder(true)
        }
    }, [inputValue])


    const editGroup = async () => {
        storeHandler(actions.SHOW_LOADER, true)
        try {
            const nhubs = inputValue.map(item => item.id)
            const { message } = await AuthServices.editGroup({ name, nhubs, id })

            const data = { message: message || "Group updated successfully", class: 'success' }
            setShowSnackBar(true)
            setSnackBarContent({ ...data })
            storeHandler(actions.SHOW_LOADER, false)
            setTimeout(() => {
                history.push(`/group?id=${id}`)
            }, 1000)

        } catch (err) {
            const data = { message: 'Check the Nhubs it is inprogess in Deployment', class: 'error' }
            setShowSnackBar(true)
            setSnackBarContent({ ...data })
            storeHandler(actions.SHOW_LOADER, false)
        }
    }

    const onSelection = (event, newInputValue) => {
        setInputValue(newInputValue);
    }

    const storeHandler = (type, payload) => dispatch({ type, payload })

    return (
        <>
            <Header searchBar={false} />
            <div className="d-flex ">
                <div className="col-lg-6 col-12 flex-grow-1 d-flex">
                    <div className={`${styles.detailsWrapper} flex-grow-1 p-4 d-flex flex-column mt-5`}>
                        <p className='mt-0'>Edit Group</p>
                        <div className='mb-4'>
                            <label className='txt-md'>Group Name <span className={styles.asteriskError}>*</span></label>
                            <TextField variant="filled" className={'withoutLabel mt-2'} value={name} inputProps={{ className: 'txt-md', name: 'groupName' }} onChange={(event) => setName(event.target.value)} type='text' fullWidth placeholder="Group Name" />
                        </div>

                        <div className='mb-4'>
                            <label className='txt-md'>Nhub's <span className={styles.asteriskError}>*</span></label>
                            {<AutoComplete filter_key={'device_id'} handleplaceholder={handleplaceholder} value={inputValue} options={options} getOptionLabel={(option) => option.device_id} noOptionsText='Nhub not Found' onChange={onSelection} />}
                        </div>
                        {!!(inputValue || []).length && <div>
                            <label className='txt-md'>Selected Nhub's</label>
                            <p className='txt-primary mt-2'>{(inputValue.map(item => item.device_id) || []).join(', ')}</p>
                        </div>}
                        <div className='text-center mt-3'>
                            <Button variant="outlined" color="primary" className='px-4 mr-3'><span className='txt-primary' onClick={() => history.push(`/group?id=${id}`)}>Cancel</span></Button>
                            <Button variant="contained" color="primary" className='px-4' onClick={editGroup}><img src={submitIcon} className='mr-2' /><span className='txt-white txt-light'>Edit Group</span></Button>
                        </div>
                    </div>
                </div>
                <Snackbar open={showSnackBar} message={snackbarContent?.message || ''} className={snackbarContent?.class || ''} autoHideDuration={4000} closeSnackBar={setShowSnackBar} />
            </div>

        </>
    )
}

export default EditGroup