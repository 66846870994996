import React from "react";

// import { Card } from "../index";
import avatar from 'assets/images/icon/default.svg';
import styles from "./nhub-card.module.scss";
import { useHistory } from "react-router-dom";
import { format, isValid } from "date-fns";

const SeniorsCard = ({ data }) => {
  const { name, profile } = data
  return (
    <div className={`${styles.senior_card} col-8`}>
      <span><img src={profile || avatar} alt="profile img" className={`${styles.nhub_senior_img}`} />{name}</span>
    </div>
  )
}

function NHubCard({ data }) {

  let history = useHistory();


  const { rpi_id, date, status, version, seniors, id } = data || {}

  const navigate = () => {
    history.push(`/nhub/${id}`)
  }

  return (
    <div className={`${styles.nhub_card} px-3 pt-3 [b-1] h-100 cursor`} onClick={navigate}>
      <div>
        <div className={`${styles.nhub_card_header}`}>
          <p className="mb-1 mt-0">{rpi_id}</p>
        </div>
        <div className={`${styles.nhub_card_date}`}>
          <p className="mb-1 mt-0">{isValid(new Date(date)) ? format(new Date(date), 'MM-dd-yyy') : '-'}</p>
        </div>
        <div className={`${styles.nhub_card_development_status} mb-1`}>
          <span>Deployment Status</span>
          {status && <span className={`
            ${styles.nhub_card_dev_status} 
            ${status === "Inprogress" && styles.nhub_card_dev_status_warning} 
            ${status === "failed" && styles.nhub_card_dev_status_error} 
            ml-3
          `}>{status}</span>}
        </div>
        <div className={`${styles.nhub_ver_no}`}>
          <span className={`${styles.nhub_card_ver} mr-3`}>Version</span>
          <span>{version || '-'}</span>
        </div>
      </div>
      <div className={`${styles.nhub_senior_con} my-2`}>
        {!!seniors?.length && <p>Seniors</p>}
        <div className={`${styles.nhub_senior_list} row`}>
          {seniors?.map(item => <SeniorsCard data={item} />)}

        </div>
      </div>
    </div>
  );
}

export default NHubCard;
