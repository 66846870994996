import styles from './create-group.module.scss'
import { Button, TextField, AutoComplete, Snackbar } from "components";
import submitIcon from 'assets/images/icon/submit-icon.svg';
import { useEffect, useState, useContext } from 'react';

import AuthServices from 'api/services/auth-services';
import { GlobalContext, actions } from 'context';
import Header from 'app/header/header';
import { useHistory } from 'react-router-dom';

const CreateGroup = () => {

    const history = useHistory()

    const { dispatch } = useContext(GlobalContext);
    const [inputValue, setInputValue] = useState([]);
    const [options, setOptions] = useState([])
    const [allGroup , setAllGroup] = useState([])
    const [isDuplicateGroup , setIsDuplicateGroup] = useState(false)
    const [name, setName] = useState('')
    const [handleplaceholder, setHandlePlaceholder] = useState(true)
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });

    useEffect(() => {
        getNhubList()
        getGroups()
    }, [])

    useEffect(() => {
        if (inputValue?.length > 0) {
            setHandlePlaceholder(false)
        } else {
            setHandlePlaceholder(true)
        }
    }, [inputValue])

    const getNhubList = async () => {
        storeHandler(actions.SHOW_LOADER, true)
        try {
            const { data } = await AuthServices.getNhubLists()
            setOptions(data)
            storeHandler(actions.SHOW_LOADER, false)
        } catch (err) {
            storeHandler(actions.SHOW_LOADER, false)
        }
    }

    const getGroups = async () => {
        storeHandler(actions.SHOW_LOADER, true)
        try {
            const { data } = await AuthServices.getGroups()
            setAllGroup(data || [] )
            storeHandler(actions.SHOW_LOADER, false)
        } catch (err) {
            storeHandler(actions.SHOW_LOADER, false)
        }
    }

    const HandleNameChange = (event) => {
        setName(event.target.value)
        checkDuplicateGroupName(event.target.value , allGroup) ? setIsDuplicateGroup(true) : setIsDuplicateGroup(false)
    }

    const checkDuplicateGroupName = (group_name = "" , allGroup = []) => {
        for(let x = 0 ; x < allGroup.length ; x++ ) {
            if(group_name.toLocaleLowerCase() === allGroup[x]?.group_name.toLocaleLowerCase()) return true    
        }
        return false
    }

    const addGroup = async () => {
        storeHandler(actions.SHOW_LOADER, true)
        try {
            const nhubs = inputValue.map(item => item.id)
            const { message } = await AuthServices.addGroup({ name, nhubs })
            const data = { message: message || 'Group created successfully' , class: 'success' }
            setShowSnackBar(true)
            setSnackBarContent({ ...data })
            storeHandler(actions.SHOW_LOADER, false)
            setTimeout(() => { 
                history.push(`/?tab=group`)
             }, 1000)

        } catch (err) {
            const data = { message: err?.data?.message || 'Error while creating the group. Please try after sometime', class: 'error' }
            setShowSnackBar(true)
            setSnackBarContent({ ...data })
            storeHandler(actions.SHOW_LOADER, false)
        }
    }

    const onSelection = (event, newInputValue) => {
        setInputValue(newInputValue);
    }

    const storeHandler = (type, payload) => dispatch({ type, payload })


    return (
        <>

            <Header searchBar={false} />
            <div className="d-flex ">
                <div className="col-lg-6 col-12 flex-grow-1 d-flex">
                    <div className={`${styles.detailsWrapper} flex-grow-1 p-4 d-flex flex-column mt-5`}>
                        <p className='mt-0'>Create Group</p>
                        <div className='mb-4'>
                            <label className='txt-md'>Group Name <span className={styles.asteriskError}>*</span></label>
                            <TextField variant="filled" className={'withoutLabel mt-2'} value={name} inputProps={{ className: 'txt-md', name: 'groupName' }} onChange={(event) => HandleNameChange(event) } type='text' fullWidth placeholder="Group Name"
                            helperText={ isDuplicateGroup && 'Group name already exists'} />
                        </div>

                        <div className='mb-4'>
                            <label className='txt-md'>Nhub's <span className={styles.asteriskError}>*</span></label>
                            {<AutoComplete filter_key={'device_id'} handleplaceholder={handleplaceholder} value={inputValue} options={options} getOptionLabel={(option) => option.device_id} noOptionsText='Nhub not Found' onChange={onSelection} />}
                        </div>
                        {!!(inputValue || []).length && <div>
                            <label className='txt-md'>Selected Nhub's</label>
                            <p className='txt-primary mt-2'>{(inputValue.map(item => item.device_id) || []).join(', ')}</p>
                        </div>}
                        <div className='text-center mt-3'>
                            <Button variant="outlined" color="primary" className='px-4 mr-3'><span className='txt-primary' onClick={() => history.push(`/?tab=group`)}>Cancel</span></Button>
                            <Button variant="contained" color="primary" className='px-4' disabled={!name || isDuplicateGroup || !(inputValue || []).length} onClick={addGroup}><img src={submitIcon} className='mr-2' /><span className='txt-white txt-light'>Create Group</span></Button>
                        </div>
                    </div>
                </div>
                <Snackbar open={showSnackBar} message={snackbarContent?.message || ''} className={snackbarContent?.class || ''} autoHideDuration={4000} closeSnackBar={setShowSnackBar} />
            </div>

        </>
    )
}

export default CreateGroup