import { useParams } from "react-router-dom"
import styles from './nhub-details.module.scss'
import data from 'json/nhub.json';
import avatar from 'assets/images/icon/default.svg';
import { useEffect, useState, useContext } from 'react';

import AuthServices from 'api/services/auth-services';
import { GlobalContext, actions } from 'context';
import { format, isValid } from 'date-fns'
import moment from "moment";
import wifiGood from "assets/images/icon/wifi-good-strength.svg"
import wifiWeek from "assets/images/icon/wifi-weak.svg"
import wifiM1 from "assets/images/icon/wifi-moderate1.svg"
import wifiM2 from "assets/images/icon/wifi-moderate2.svg"
import wifiOff from "assets/images/icon/wifi-off.svg"
import lanConnected from "assets/images/icon/LAN_Connected.svg"
import lanNotConnected from "assets/images/icon/LAN_Not_Connected.svg"

import { Tooltip } from "@material-ui/core";
import Header from "app/header/header";
import DeploySteps from "./deployment-step/deploy-steps";
import newwifiGood from "assets/images/icon/wifi-newpatch-goodstrength.svg"
import newwifiWeek from "assets/images/icon/wifi-newpatch-weak.svg"
import newwifiM1 from "assets/images/icon/wifi-newpatch-moderate1.svg"
import newwifiM2 from "assets/images/icon/wifi-newpatch-moderate2.svg"

const SeniorsCard = ({ data: { category, id, location, master_id, profile, plan, name, senior_id } }) => {
    return (
        <div className={`${styles.senior_card} col-6`}>
            <span><img src={profile || avatar} alt="profile img" className={`${styles.nhub_senior_img}`} />{name}</span>
        </div>
    )
}

const Row = ({ customclass, data }) => {

    const { assigned, updated, version } = data || {}

    return (<div className={`${styles.tableRow} ${styles.customclass} d-flex flex-wrap`}>
        <div className="col-4 py-2 px-2">{isValid(new Date(updated)) ? format(new Date(updated), 'MM-dd-yyyy') : '-'}</div>
        <div className="col-4 py-2 px-2">{version}</div>
        <div className="col-4 py-2 px-2">{isValid(new Date(assigned)) ? format(new Date(assigned), 'MM-dd-yyyy') : '-'}</div>
    </div>)
}

const NhubDetails = () => {

    const { dispatch } = useContext(GlobalContext);

    const { id } = useParams()

    const [nhubDetails, setNhubDetails] = useState({});
    const [seniorData, setSeniourData] = useState({})
    const { rpi_id, deployment_status, ip_last_update, wifi_mac, group_name, seniors, firmware, eth_mac, current_patch, current_release, deploy_timeline_status,external_wifi } = nhubDetails || {}
    const { category, location,room_no, master_id, name, senior_id, wifi_strength, network_type } = seniorData || {}
    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = async () => {
        storeHandler(actions.SHOW_LOADER, true)
        try {
            const { data } = await AuthServices.getNhubDetail(id)
            setNhubDetails({ ...data })
            setSeniourData(data?.seniors[0])
            storeHandler(actions.SHOW_LOADER, false)
        } catch (err) {
            storeHandler(actions.SHOW_LOADER, false)
        }
    }

    const handleCheckWifiStrength = () => {
        if(external_wifi===true){
            if (wifi_strength === "Not Good") return wifiWeek;
            if (wifi_strength === "Okay") return wifiM2;
            if (wifi_strength === "Very Good") return wifiM1;
            if (wifi_strength === "Amazing") return wifiGood;
        }else if(external_wifi===false||external_wifi===null){
            if (wifi_strength === "Not Good") return newwifiWeek;
            if (wifi_strength === "Okay") return newwifiM2;
            if (wifi_strength === "Very Good") return newwifiM1;
            if (wifi_strength === "Amazing") return newwifiGood;
        }
        else return wifiWeek
    }

    const storeHandler = (type, payload) => dispatch({ type, payload })


    return (
        <>
            <Header searchBar={false} />
            <div className="d-flex body_container flex-grow-1">
                <div className="col-lg-9 col-12 flex-grow-1 d-flex">
                    <div className={`${styles.detailsWrapper} flex-grow-1 p-3 d-flex flex-column`}>
                        <div className="d-flex flex-wrap border-bottom">
                            <div className="flex-grow-1 d-flex justify-content-between col-7 flex-wrap my-2 px-0">
                                <div className="d-flex flex-column col-7  px-0 mb-3">
                                    <span className="txt-grey-drk txt-md mb-2">RPI Device ID</span>
                                    <div className="d-flex align-items-center"><span className="txt-primary txt-lg">{rpi_id}</span>

                                        {seniorData && Object.keys(seniorData)?.length && network_type === "Wi-Fi" && (
                                            moment.utc().diff(ip_last_update, 'minutes') < 5 && !!wifi_strength ? (
                                                <Tooltip title={wifi_strength || "Not Good"}>
                                                    <img src={handleCheckWifiStrength()} width={"15px"} height={"15px"} className='ml-3' alt="activeWifi" />
                                                </Tooltip>
                                            ) : (
                                                <img src={wifiOff} width={"15px"} height={"15px"} className='ml-3' alt="inactiveWifi" />
                                            )
                                        )}

                                        {seniorData && Object.keys(seniorData)?.length && network_type === "LAN" && (
                                            moment.utc().diff(ip_last_update, 'minutes') < 5 ? (
                                                <img src={lanConnected} className='ml-3' alt="lanConnected" />
                                            ) : (
                                                <img src={lanNotConnected} className='ml-3' alt="lanNotConnected" />
                                            )
                                        )}


                                        <span className={`${styles.nhub_card_dev_status} ${!(moment.utc().diff(ip_last_update, 'minutes') < 5) && styles.nhub_card_dev_status_not_active} ml-3`}>{(moment.utc().diff(ip_last_update, 'minutes') < 5) ? "Online" : 'Offline'}</span>

                                    </div>
                                </div>
                                <div className="d-flex flex-column  col-5 mb-3">
                                    <span className="txt-grey-drk txt-md mb-2">Last Updated Date</span>
                                    <span className="txt-primary txt-lg">{isValid(new Date(ip_last_update)) ? format(new Date(ip_last_update), 'MM-dd-yyyy') : '-'}</span>
                                </div>
                                <div className="d-flex flex-column col-7  px-0 mb-3">
                                    <span className="txt-grey-drk txt-md mb-2">Deployment Status</span>
                                    {deployment_status && <div>
                                        <span className={`
                                            ${styles.nhub_card_dev_status}
                                            ${deployment_status === "Inprogress" && styles.nhub_card_dev_status_progress} 
                                            ${deployment_status === "failed" && styles.nhub_card_dev_status_not_active} 
                                        `}>
                                            {deployment_status || "-"}
                                        </span>
                                    </div>}
                                    {!deployment_status && "-"}

                                </div>
                                <div className="d-flex flex-column  col-5 mb-3">
                                    <span className="txt-grey-drk txt-md mb-2">RPI Version</span>
                                    <span className="txt-primary txt-lg">{current_release || "-"}</span>
                                </div>
                            </div>
                            <div className="col-5"><div className={`${styles.nhub_senior_con} my-2`}>
                                {!!seniors?.length && <p>Seniors</p>}
                                <div className={`${styles.nhub_senior_list} row`}>
                                    {seniors?.map(item => <SeniorsCard data={item} />)}

                                </div>
                            </div></div>
                            {deploy_timeline_status && <DeploySteps deploy_timeline_status={deploy_timeline_status} />}
                        </div>
                        <div className="d-flex flex-wrap flex-grow-1 pt-3">
                            <div className="col-5 border-right d-flex flex-column">
                                <div className="d-flex flex-column mb-4">
                                    <span className="txt-grey-drk txt-md mb-2">Wifi Mac ID</span>
                                    <span className="txt-primary txt-lg">{wifi_mac || "-"}</span>
                                </div>
                                <div className="d-flex flex-column mb-4">
                                    <span className="txt-grey-drk txt-md mb-2">Ethernet ID</span>
                                    <span className="txt-primary txt-lg">{eth_mac || "-"}</span>
                                </div>
                                <div className="d-flex flex-column mb-4">
                                    <span className="txt-grey-drk txt-md mb-2">Patch Version</span>
                                    <span className="txt-primary txt-lg">{current_patch || "-"}</span>
                                </div>
                                <div className="d-flex flex-column mb-4">
                                    <span className="txt-grey-drk txt-md mb-2">Group Name</span>
                                    <span className="txt-primary txt-lg">{group_name || "-"}</span>
                                </div>
                                <div className="d-flex flex-column mb-4">
                                    <span className="txt-grey-drk txt-md mb-2">Account</span>
                                    <span className="txt-primary txt-lg">{master_id || "-"}</span>
                                </div>
                                <div className="d-flex flex-column mb-4">
                                    <span className="txt-grey-drk txt-md mb-2">Category</span>
                                    <span className="txt-primary txt-lg">{category || "-"}</span>
                                </div>
                                <div className="d-flex flex-column mb-4">
                                    <span className="txt-grey-drk txt-md mb-2">Location</span>
                                    <span className="txt-primary txt-lg">{location || "-"}</span>
                                </div>
                                <div className="d-flex flex-column mb-4">
                                    <span className="txt-grey-drk txt-md mb-2">Room Number</span>
                                    <span className="txt-primary txt-lg">{room_no || "-"}</span>
                                </div>
                            </div>
                            <div className="col-7">
                                <p className="txt-grey-drk mb-3 px-4">Latest Firmware version {firmware?.[firmware.length - 1]?.version}</p>

                                <div className="px-4">
                                    <div className={`${styles.table}`}>
                                        <div className={`${styles.tableHeader} d-flex flex-wrap`}>
                                            <div className="col-4 py-2 px-2">Updated on</div>
                                            <div className="col-4 py-2 px-2">Version</div>
                                            <div className="col-4 py-2 px-2">Assigned on</div>
                                        </div>

                                        {firmware && firmware?.length > 0 ? firmware.map(item => <Row key={item.version} data={item} />) : (
                                            <div className={`${styles.tableRow} ${styles.customclass} d-flex flex-wrap`}>
                                                <div className="col-12 py-2 px-2">{"No Data Avilable"}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NhubDetails