import { useContext, useEffect } from 'react';
import { Switch } from 'react-router-dom';

import { PreLogIn, PostLogIn } from 'layouts';
import { PrivateRoute, PublicRoute } from 'routes';

import Login from 'app/login/login';
import ForgotPassword from 'app/forget-password/forget-password';

import Dashboard from 'app/dashboard/dashboard';
import Report from 'app/report/report';
import Release from 'app/release/release';
import Deployment from 'app/deployment/deployment';
import NhubDetails from './dashboard/nhub/nhub-details/nhub-details';
import ReleaseDetails from './release/release-details/release-details';
import DeploymentDetails from './deployment/deploy-details/deployment-details';

import { GlobalContext, actions } from 'context';
import CircularProgress from '@material-ui/core/CircularProgress';
import GropuDetails from './dashboard/group/group-details/group-details';
import EditGroup from './dashboard/group/edit-group/edit-group';
import CreateGroup from './dashboard/group/create-group/create-group';
import CreateRelease from './release/create-release/create-release';
import EditDeployment from './deployment/edit-deploy/edit-deployment';
import CreateDeployment from './deployment/create-deploy/create-deployment';


const App = () => {

    const { state: { showLoader }, dispatch } = useContext(GlobalContext);

    useEffect(() => {
        validateLogIn()
    }, [])

    const validateLogIn = () => {
        let token = localStorage.getItem('authToken');
        storeHandler(actions.LOG_IN, !!token);

    }
    const storeHandler = (type, payload) => dispatch({ type, payload })

    const Help = () => {
        return (
            <>
                <div className='txt-white'>

                    Build Version: <span className='txt-primary'> {process.env.REACT_APP_VERSION} </span> <br /> <br />
                    Date:  <span className='txt-primary'>{process.env.REACT_APP_BUILD_DATE}</span>
                </div>
            </>
        )
    }

    return (
        <div>
            {showLoader && <div className='loader'>
                <CircularProgress />
            </div>}
            <Switch>
                <PublicRoute path="/help" component={Help} layout={PreLogIn} />
                <PublicRoute path="/login" component={Login} layout={PreLogIn} />
                <PublicRoute path="/forgot-password" component={ForgotPassword} layout={PreLogIn} />
                <PrivateRoute path="/report" component={Report} layout={PostLogIn} />
                <PrivateRoute path="/release" component={Release} layout={PostLogIn} />
                <PrivateRoute path="/release-details" component={ReleaseDetails} layout={PostLogIn} />
                <PrivateRoute path="/create-release" component={CreateRelease} layout={PostLogIn} />
                <PrivateRoute path="/deployment/" component={Deployment} layout={PostLogIn} />
                <PrivateRoute path="/deployment-details/" component={DeploymentDetails} layout={PostLogIn} />
                <PrivateRoute path="/edit-deployment/" component={EditDeployment} layout={PostLogIn} />
                <PrivateRoute path="/create-deployment/" component={CreateDeployment} layout={PostLogIn} />
                <PrivateRoute path="/group" component={GropuDetails} layout={PostLogIn} />
                <PrivateRoute path="/create-group" component={CreateGroup} layout={PostLogIn} />
                <PrivateRoute path="/edit-group" component={EditGroup} layout={PostLogIn} />
                <PrivateRoute path="/nhub/:id" component={NhubDetails} layout={PostLogIn} />
                <PrivateRoute path="/" component={Dashboard} layout={PostLogIn} />
            </Switch>
        </div>
    )
}

export default App