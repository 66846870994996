import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    TimePicker as MuiTimePicker
} from "@material-ui/pickers";
import { ReactComponent as ClockIcon } from 'assets/images/icon/clock_icon2.svg';
import { TextField } from '@material-ui/core';

const textFieldComponent = (TextFieldProps) => (
    <TextField
        {...TextFieldProps}
        variant="filled"
        placeholder="Select Time"
        disableUnderline
        className='withoutLabel'
        InputProps={{
            ...TextFieldProps.InputProps,
            endAdornment: <ClockIcon className="calendarIcon" />,
            disableUnderline: true
        }}
    />
);


export default function TimePicker(props) {



    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiTimePicker
                {...props}
                margin="dense"
                TextFieldComponent={textFieldComponent}
                KeyboardButtonProps={{
                    "aria-label": "change time"
                }}
            />
        </MuiPickersUtilsProvider>
    );
}
