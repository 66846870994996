import React from 'react'
import styles from './deploy-card.module.scss';
import { format, isValid } from "date-fns";
import { useHistory } from "react-router-dom";


function DeployCard({ data }) {

  let history = useHistory();
  const getSearchParam = (val) => new URLSearchParams(history.location.search).get(val)
  const {deploy_id, registered_date, rpi_count, group:{group_name}, id , datetime} = data || {}
  
  const navigate = () => {
    history.push(`/deployment-details?tab=${getSearchParam('tab')}&id=${id}`)
  }

  return (
    <div className={`${styles.release_card} px-3 py-3 h-100 cursor`} onClick={navigate} >
      <div className={`${styles.release_card_header}`}>
        <p className='mb-3 mt-0'>{deploy_id}</p>
      </div>

      <div className='d-flex flex-wrap'>
        <div className='col-6 px-0'>
          <div className='txt-smd mb-2'>Deployment Date</div>
          <div className='txt-md txt-primary mb-4'>{isValid(new Date(datetime)) ? format(new Date(datetime), 'MM-dd-yyyy') : '-' }</div>
        </div>
        <div className='col-6  px-0'>
          <div className='txt-smd mb-2'>Schedule Time</div>
          <div className='txt-md  txt-primary mb-4'>{isValid(new Date(datetime)) ? format(new Date(datetime), 'hh:mm aa') : '-' }</div>
        </div>
        <div className='col-6 px-0'>
          <div className='txt-smd mb-2'>Device Compatible</div>
          <div className='txt-md txt-primary'>{rpi_count ? rpi_count : "-"}</div>
        </div>
        {group_name && <div className='col-6 px-0'>
          <div className='txt-smd mb-2'>Group Name</div>
          <div className='txt-md txt-primary'>{group_name}</div>
        </div>}
      </div>
    </div>
  )
}

export default DeployCard