import { useContext, useState } from 'react';
import styles from './login.module.scss';
import { TextField, Button, Snackbar } from 'components';
import { Link } from 'react-router-dom';
import AuthServices from 'api/services/auth-services';

import logo from 'assets/images/background/logo-login.png'
import sendIcon from 'assets/images/icon/mail-icon.svg'

import { GlobalContext, actions } from 'context';
import { getErrorMessage } from 'constants/constants'

const Login = () => {

    const { dispatch } = useContext(GlobalContext);
    const [credientials, setCredientials] = useState({});

    const inputFields = ['mobile', 'password'];
    const [invalidForm, setInvalidForm] = useState(false);
    const [fromError, setFormError] = useState();

    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });

    const storeHandler = (type, payload) => dispatch({ type, payload });

    const validate = (event) => {
        event.preventDefault()
        let invalidFields = [];
        inputFields.forEach(item => {
            if (!credientials[item]) {
                invalidFields.push(item)
            }
        })
        if (invalidFields.length) {
            setInvalidForm(true)
        } else {
            logIn();
            setInvalidForm(false)
        }
    }

    const logIn = async () => {
        
        storeHandler(actions.SHOW_LOADER, true)

        try {
            const { access_token, refresh_token, message, name} = await AuthServices.login(credientials);
            localStorage.setItem('authToken', access_token);
            localStorage.setItem('refreshToken', refresh_token);
            localStorage.setItem('name', name)
            storeHandler(actions.SHOW_LOADER, false)

            const data = { message, class: 'success' }
            setShowSnackBar(true);
            setSnackBarContent({ ...data });
            setTimeout(() => {
                storeHandler(actions.LOG_IN, {login:true , name});
                window.location.href = "/"
            }, 1000)

        } catch (err) {
            const { data } = err || {};
            const { message } = data || {};
            if(message === "You are not an Admin!" ){
                setShowSnackBar(true);
                setSnackBarContent({ message, class: 'error'});
            }
            setFormError(message);
            setInvalidForm(true)
            storeHandler(actions.SHOW_LOADER, false)
        }
    }

    const loginInfo = (key, value) => {
        if(key === 'mobile' && value?.length > 10) return
        const data = { ...credientials, [key]: value }
        setCredientials({ ...data })
        invalidForm && setInvalidForm(false)
    }

    return (
        <div className={`${styles.loginContainer} text-center`}>
            <img src={logo} alt="logo" width={"100%"} className='mb-4 w-lg-75' />
            <form onSubmit={validate}>
                <TextField label="Phone number" variant="filled" fullWidth className={'mb-3'} type='number' value={credientials?.mobile || ''}
                    onChange={(event) => loginInfo('mobile', event.target.value)} helperText={getErrorMessage(invalidForm, 'mobile', credientials?.mobile, fromError)} />

                <TextField label="Password" variant="filled" fullWidth className={'mb-3'} passwordfield={true} value={credientials?.password || ''}
                    onChange={(event) => loginInfo('password', event.target.value)} helperText={getErrorMessage(invalidForm, 'password', credientials?.password, fromError)} />

                <Button type='submit' variant="contained" color="primary" className={'btn-lg txt-lg mb-3'} >
                    <img src={sendIcon} alt="send" className='mr-3' /> Login
                </Button>
            </form>
            <Link className='txt-primary txt-xmd txt-light' to="/forgot-password">Forgot Password</Link>
            <Snackbar open={showSnackBar} message={snackbarContent?.message || ''} className={snackbarContent?.class || ''} autoHideDuration={4000} closeSnackBar={setShowSnackBar} />
        </div>
    )
}

export default Login