import { useHistory } from "react-router-dom"
import styles from './deployment-details.module.scss'
import { useEffect, useState, useContext } from 'react';
import EditIcon from "assets/images/icon/edit.svg";
import AuthServices from 'api/services/auth-services';
import { GlobalContext, actions } from 'context';
import { format, isValid } from 'date-fns'
import NHubCard from '../../dashboard/nhub/nhub-card/nhub-card';
import Header from "app/header/header";
import CreateDeployment from "../create-deploy/create-deployment";
import { timeDifference } from "utils/validator";
import { Tooltip } from "@material-ui/core";

const DeploymentDetails = () => {

    const { dispatch } = useContext(GlobalContext);
    const getSearchParam = (val) => new URLSearchParams(history.location.search).get(val)
    const history = useHistory()
    const status = getSearchParam('tab')
    const id = getSearchParam('id')
    const [deploymentDetails, setDeploymentDetails] = useState({});

    const { rpi_count, group, release_name, datetime } = deploymentDetails || {};

    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = async () => {
        storeHandler(actions.SHOW_LOADER, true)
        try {
            const { deployment } = await AuthServices.getDeploymentDetail(id)
            setDeploymentDetails({ ...deployment })
            storeHandler(actions.SHOW_LOADER, false)
        } catch (err) {
            storeHandler(actions.SHOW_LOADER, false)
        }
    }

    const handleDateChange = (date) => {
        if (timeDifference(date, new Date) > 3600000) {
            return false
        } else {
            return true
        }
    }

    const storeHandler = (type, payload) => dispatch({ type, payload })

    return (
        <>
            <Header searchBar={false} />
            <div className="d-flex body_container flex-grow-1">
                <div className="col-12 flex-grow-1 d-flex">
                    <div className={`flex-grow-1 d-flex flex-column mb-5`}>
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="flex-grow-1 d-flex justify-content-between col-9 flex-wrap my-2 px-0">
                                <div className="d-flex flex-column col-4 px-0 mb-3">
                                    <span className="txt-grey-drk txt-md mb-2">Deployment Date</span>
                                    <div className="d-flex align-items-center"><span className="txt-primary txt-lg">{isValid(new Date(datetime)) ? format(new Date(datetime), 'MM-dd-yyyy') : '-'}</span> </div>
                                </div>
                                <div className="d-flex flex-column col-4 mb-3">
                                    <span className="txt-grey-drk txt-md mb-2">Group Name</span>
                                    <div><span className={`txt-primary txt-lg`}>{group?.length > 0 ? group?.map((id) => { return (id.group_name) }).join(",") : '-'}</span></div>
                                </div>
                                <div className="d-flex flex-column col-4 mb-3">
                                    <span className="txt-grey-drk txt-md mb-2">Release Name</span>
                                    <div><span className={`txt-primary txt-lg`}>{release_name || '-'}</span></div>
                                </div>
                                <div className="d-flex flex-column col-4 px-0 mb-3">
                                    <span className="txt-grey-drk txt-md mb-2">Schedule Time</span>
                                    <div><span className={`txt-primary txt-lg`}>{isValid(new Date(datetime)) ? format(new Date(datetime), 'hh:mm a') : '-'}</span></div>

                                </div>
                                <div className="d-flex flex-column col-8 mb-3">
                                    <span className="txt-grey-drk txt-md mb-2">Device Compatible</span>
                                    <span className="txt-primary txt-lg">{rpi_count ? rpi_count : "-"}</span>
                                </div>
                            </div>
                            { status === "scheduled" && <Tooltip arrow title={!handleDateChange(new Date(datetime)) ? "Edit Deployment" : "Deployment is about to begin"}>
                                <div className={`${!handleDateChange(new Date(datetime)) && 'cursor'}`} onClick={() => !handleDateChange(new Date(datetime)) && history.push(`/edit-deployment?tab=${status}&id=${id}`)}>
                                    <span className="txt-grey-drk txt-xmd mb-1 mr-2 mt-1">Edit</span>
                                    <img className={`${styles.svgstyle}`} src={EditIcon} alt="Edit Icon" />
                                </div>
                            </Tooltip>}
                        </div>
                        <div className="">
                            <p className="pb-2 border-bottom">Nhubs</p>
                            <div className='d-flex flex-wrap'>

                                {deploymentDetails?.rpi?.map((val, index) => (
                                    <>
                                        <div className='col-12 col-md-6 col-lg-3 mb-3' key={index}>
                                            <NHubCard data={val} />
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DeploymentDetails