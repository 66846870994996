const LOG_IN = 'LOG_IN';
const SHOW_LOADER = 'SHOW_LOADER';
const SET_USER_DETAILS = 'SET_USER_DETAILS';
const SET_RELEASE_DETAILS = 'SET_RELEASE_DETAILS';
const SET_GROUP_DETAILS = 'SET_GROUP_DETAILS';
const SET_TAB_VALUE='SET_TAB_VALUE'

export {
    LOG_IN,
    SHOW_LOADER,
    SET_USER_DETAILS,
    SET_RELEASE_DETAILS,
    SET_GROUP_DETAILS,
    SET_TAB_VALUE
};
