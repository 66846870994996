import styles from './postlogin.module.scss';
import Header from 'app/header/header';
import logo from 'assets/images/background/logo.png'
import { ReactComponent as NHubIcon } from "assets/images/icon/nhub_icon.svg";
import { ReactComponent as ReleaseIcon } from "assets/images/icon/release_icon.svg";
import { ReactComponent as DeploymentIcon } from "assets/images/icon/deployment_settings_icon.svg";
import { ReactComponent as ReportIcon } from "assets/images/icon/report_icon.svg";
import { NavLink, useLocation } from 'react-router-dom';

const PostLogin = ({ children }) => {

    let { pathname } = useLocation();
    const URL = pathname?.replace('/', '')?.split('/')[0]
    
    return (
        <div className={`${styles.mainWrapper} p-2 d-flex`}>
            <div className={`${styles.navWrapper} p-2 d-flex flex-column align-items-center`}>
                <img src={logo} alt="logo" className={`${styles.appLogo} mb-1`} />
                <div className='d-flex flex-column flex-grow-1 justify-content-center mb-5'>
                    <NavLink to="/" className={`${styles.navIcon} ${(URL === "" || URL === "/" || URL === "nhub" || URL === "create-group" || URL === "group" || URL === "edit-group") && "active-link" } my-3 d-flex flex-column align-items-center`}>
                        <NHubIcon />
                        <span className='txt-xs txt-lt mt-1 link-text'>Nhub</span>
                    </NavLink >
                    <NavLink to="/release" className={`${styles.navIcon} ${(URL === "release-details" || URL === "release" || URL === "create-release") && "active-link" } my-3 d-flex flex-column align-items-center`}>
                        <ReleaseIcon />
                        <span className='txt-xs txt-lt mt-1 link-text'>Release</span>
                    </NavLink >
                    <NavLink to="/deployment" className={`${styles.navIcon} ${(URL === "deployment" || URL === "deployment-details" || URL === "edit-deployment" || URL === 'create-deployment') && "active-link" } my-3 d-flex flex-column align-items-center`}>
                        <DeploymentIcon />
                        <span className='txt-xs txt-lt mt-1 link-text'>Deployment</span>
                    </NavLink >
                    <NavLink to="/report" className={`${styles.navIcon} ${(URL === "report") && "active-link" } my-3 d-flex flex-column align-items-center`}>
                        <ReportIcon />
                        <span className='txt-xs txt-lt mt-1 link-text'>Report</span>
                    </NavLink >
                </div>
            </div>
            <div className='flex-grow-1 px-2 d-flex flex-column'>
                {children}
            </div>
        </div>
    )
}

export default PostLogin