import styles from './create-deployment.module.scss'
import { Button, DatePicker, AutoComplete, Select, MenuItem, TextField, Snackbar, TimePicker, ConfirmDialog } from "components";
import submitIcon from 'assets/images/icon/submit-icon.svg';
import { useEffect, useState, useContext } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import AuthServices from 'api/services/auth-services';
import { GlobalContext, actions } from 'context';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from '@material-ui/core';
import { ReactComponent as Infoicon } from 'assets/images/icon/info_icon3.svg';
import { Radio } from '@material-ui/core';
import { timeDifference } from 'utils/validator';
import Header from 'app/header/header';
import { useHistory } from 'react-router-dom';

const errorMessages = {
    type: 'Select Eligibility',
    nhubs: 'Select the details',
    min: 'Select min version',
    max: 'Select max version',
    release: 'Select a release',
    retry: 'Enter Retry times',
    date: 'Select a date',
}


const CreateDeployment = () => {

    const history = useHistory()
    const getSearchParam = (val) => new URLSearchParams(history.location.search).get(val)
    const tab = getSearchParam("tab")

    const { dispatch } = useContext(GlobalContext);
    const [inputValue, setInputValue] = useState([]);
    const [optionNhub, setOptionNhub] = useState([])
    const [getNhub, setGetNhub] = useState([])
    const [effectedNhub, setEffectedNhub] = useState('-')
    const [optionReleases, setOptionReleases] = useState([])
    const [optionCondition, setOptionCondition] = useState([])
    const [optionGroups, setOptionGroups] = useState([])
    const [filteredVersions, setFileredVersions] = useState([])
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });
    const [deploymentDetails, setDeploymentDetails] = useState({
        type: "individual",
    })
    const { type, nhubs, release, retry, date, min, max, time } = deploymentDetails || {}
    const [handleplaceholder, setHandlePlaceholder] = useState(true)
    const [invalidForm, setInvalidForm] = useState(false);
    const [defaultRetry, setDefaultRetry] = useState('5');
    const [radioChecked, setRadioChecked] = useState(false);
    const [retryData, setRetryData] = useState('');
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [isInvalidTime, setIsInvalidTime] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({
        onClose: '',
        open: false,
        message: `Device(s) are up to date. Deploying this patch may cause issues with existing device configuration. Are you sure want to continue ? Device(s) impacted:`,
        isPrompt: false,
    })
    const [groupedNhubs, setgroupedNhubs] = useState([])
    const [impacted, setImpacted] = useState([])
    const onConfirmDialog = () => {
        saveDetails()
        onConfirmDialogClose()
    }

    const onConfirmDialogClose = () => {
        setConfirmDialog(pre => ({ ...pre, open: false }))
    }

    const openConfirmDialog = () => {
        setConfirmDialog(pre => ({ ...pre, open: true }))
    }


    const handleScreenSize = () => {
        setScreenSize(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener('resize', handleScreenSize)
        handleDateChange(deploymentDetails['date'])
        return () => window.removeEventListener('resize', handleScreenSize);
    }, [])

    useEffect(() => {
        getDeploymentDetails()
    }, [])


    useEffect(() => {
        if (inputValue?.length > 0) {
            setHandlePlaceholder(false)
        } else {
            setHandlePlaceholder(true)
        }
    }, [inputValue])

    useEffect(() => {

        const getVersion = (version) => Number((version || '0')?.split('.')[0])

        if (type === 'conditional') {
            const versions = optionReleases.map(({ version }) => getVersion(version))
            const defaultMin = getVersion(min?.version) || Math.min(...versions)
            const defaultMax = getVersion(max?.version) >= defaultMin ? getVersion(max?.version) : Math.max(...versions)
            const filteredVersions = optionReleases.filter(({ version }) => {
                const ver = getVersion(version)
                return ver >= defaultMin && ver <= defaultMax
            })
            setFileredVersions(filteredVersions)
            getConditionalNhubList(min?.version, max?.version, type)
        } else {
            setFileredVersions(optionReleases)
        }

    }, [min, max, type])

    const getConditionalNhubList = async (start_version, end_version, type) => {

        if (!end_version || !start_version || type !== "conditional") {
            console.log("enter correct version ")
            return
        }

        storeHandler(actions.SHOW_LOADER, true)
        try {
            const body = {
                start_version,
                end_version
            }
            const { nhubs } = await AuthServices.addConditionalDeployment(body)
            setOptionCondition(nhubs)
            setInputValue([])
            storeHandler(actions.SHOW_LOADER, false)

        } catch (err) {
            const data = { message: err.message || err.statusText || 'Error while getting the release version data. Please try after sometime', class: 'error' }
            setShowSnackBar(true)
            storeHandler(actions.SHOW_LOADER, false)
        }

        storeHandler(actions.SHOW_LOADER, false)

    }

    useEffect(() => {
        if (type === 'individual') {
            setDeploymentDetails({ ...deploymentDetails, nhubs: [], min: '', max: '' })
            onSelection("", [])
        } else if (type === 'group') {
            setDeploymentDetails({ ...deploymentDetails, nhubs: [], min: '', max: '' })
            onSelection("", [])
        } else if (type === 'conditional') {
            setDeploymentDetails({ ...deploymentDetails, nhubs: [] })
            onSelection("", [])
        }

    }, [type])

    const handleRadio = ((event) => {
        setRetryData(defaultRetry);
        updateDeploymentDetails(event);
        setRadioChecked(true);
    });

    const handleRadioCheck = ((event) => {
        if (event.target.value.length >= 0 || event.target.value.length > defaultRetry.length) {
            setRetryData(event.target.value);
            setRadioChecked(false)
        };
        updateDeploymentDetails(event);
    })

    const getDeploymentDetails = async () => {

        storeHandler(actions.SHOW_LOADER, true)
        const { groups, nhubs, releases } = await AuthServices.getDeploymentLists()
        setOptionReleases(releases)
        setFileredVersions(releases)
        filterGroups(groups)
        filterNhubs(nhubs);
        setGetNhub(nhubs)
        storeHandler(actions.SHOW_LOADER, false)

    }

    const updateDeploymentDetails = (event) => {
        let { name, value } = event.target;
        setDeploymentDetails({ ...deploymentDetails, [name]: value })
        if (name === "type") setInputValue([])
    }

    const handleDateChange = (date, show = false) => {
        if (timeDifference(date, new Date) > -60000) {
            setIsInvalidTime(false)
        } else {
            setIsInvalidTime(true)
        }
        setDeploymentDetails({ ...deploymentDetails, date })
    }
    const onSelection = (event, newInputValue) => {
        setInputValue(newInputValue);
    }

    const createDeployment = async () => {
        storeHandler(actions.SHOW_LOADER, true)
        try {
            const releaseID = () => {
                for (let x = 0; x < optionReleases.length; x++) {
                    if (optionReleases[x].name === deploymentDetails['release']) {
                        return optionReleases[x].id
                    }
                }
            }
            const nhubs = inputValue?.map(item => item.id)
            const { message } = await AuthServices.addDeployment({ ...deploymentDetails, release: releaseID(), nhubs, min: min?.version || '', max: max?.version || '', date: date?.toUTCString() })
            const data = { message: message || 'Deployment created successfully', class: 'success' }
            setShowSnackBar(true)
            setSnackBarContent({ ...data })
            storeHandler(actions.SHOW_LOADER, false)
            setTimeout(() => { history.push(`/deployment?tab=${tab}`) }, 1000)

        } catch (err) {
            const data = { message: err.message || err.statusText || 'Error while creating the Deployment. Please try after sometime', class: 'error' }
            setShowSnackBar(true)
            setSnackBarContent({ ...data })
            storeHandler(actions.SHOW_LOADER, false)
        }

        storeHandler(actions.SHOW_LOADER, false)

    }

    const storeHandler = (type, payload) => dispatch({ type, payload })

    const saveDetails = () => {
        let isInvalidForm = false;
        let requiredFields = Object.keys(errorMessages);
        requiredFields.forEach(item => {

            if (item === 'nhubs' && !inputValue.length) {
                isInvalidForm = true
                setInvalidForm(true);
                return
            } else if ((item === 'min' || item === 'max')) {
                if (type === 'conditional' && !deploymentDetails[item]) {
                    isInvalidForm = true
                    setInvalidForm(true);
                    return
                }

            }
            else if (!deploymentDetails[item] && item !== 'file') {
                isInvalidForm = true
                setInvalidForm(true);
                return
            }
        })
        if (!isInvalidForm && !isInvalidTime) {
            createDeployment()
        }
    }

    const getErrorMessage = (field) => {
        let message = errorMessages[field]
        return invalidForm && (field === 'nhubs' ? !inputValue.length && message : !deploymentDetails[field] && message)
    }

    const filterGroups = (groups) => {
        let finalArr = []
        groups?.forEach((el1, ind1) => {
            let seniors = []
            let group_name = el1?.name
            let id = el1?.id
            el1?.seniors?.forEach(el2 => {
                el2?.forEach(el3 => {
                    seniors.push(el3.name)
                })
            })
            finalArr.push({ group_name, seniors, id })
        })
        setOptionGroups(finalArr)
    }

    const filterNhubs = (nhubs) => {
        let finalArr = []
        nhubs?.forEach(el1 => {
            let seniors = []
            let rpi_id = el1?.rpi_id
            let id = el1?.id
            el1?.seniors?.forEach(el2 => {
                seniors.push(el2.name)
            })
            finalArr.push({ rpi_id, seniors, id })
        })
        setOptionNhub(finalArr)
    }

    const FilterDuplicateVersion = (value) => {

        if (!Array.isArray(value)) return []
        return value?.filter((el, ind, arr) => ind === arr.findIndex(o => o.version === el.version))

    }
    const getDetail = async () => {
        // storeHandler(actions.SHOW_LOADER, true)
        let updatedGroupNhubs = []
        let groupData = []
        for (const group of inputValue) {

            try {
                const { data } = await AuthServices.detailGroup(group?.id)
                const Nhub = data?.nhubs
                groupData = groupData.concat(data)
                updatedGroupNhubs = updatedGroupNhubs.concat(Nhub)



            } catch (err) {
                storeHandler(actions.SHOW_LOADER, false)
            }
        }
        setgroupedNhubs(groupData)
        return updatedGroupNhubs;


    }
    const findGroupName = (idtoFind) => {
        for (const item of groupedNhubs) {
            if (item.group.nhub_rpi.some((id) => id === idtoFind)) {
                return item.group.group_name;
            }
        }
    }
    const checkAlreadyLinkedNHUB = async (type) => {

        let isIDPresent = false
        let newInputValue = inputValue
        let releaseName = optionReleases?.filter(item => item['name'] === deploymentDetails['release'])


        if (type === "group") {
            const newIdArr = await getDetail()
            newInputValue = newIdArr
        }

        let nhubWithReleases = []
        getNhub?.forEach(item => {
            newInputValue?.forEach(i => {
                if (i['id'] === item['id']) {
                    nhubWithReleases.push(item)
                }
            })

        })

        let list = []
        nhubWithReleases?.forEach(item => {

            item['releases']?.forEach(i => {
                if (i === releaseName[0]['id']) {
                    list.push(item['rpi_id'])
                    isIDPresent = true

                }
            })

        })
        list = [...new Set(list)]
        const groupNames = newInputValue.map((item) => findGroupName(item.id))
        setImpacted(groupNames)
        if (isIDPresent) {
            openConfirmDialog()
            setEffectedNhub(list)
        } else {
            saveDetails()
        }


    }


    return (
        <>
            <Header searchBar={false} />
            <div className="d-flex body_container flex-grow-1">
                <div className="col-lg-7 col-12 flex-grow-1 d-flex">
                    <div className={`${styles.detailsWrapper} flex-grow-1 p-3 d-flex flex-column`}>

                        <div>
                            <p className='mt-0 px-3 pt-2'>Create Deployment</p>
                            <div className='col-lg-12 col-md-12 col-12 mb-4'>
                                <label className={`txt-md ${styles.txtColor}`}>Eligibility <span className={styles.asteriskError}>*</span></label>
                                <Select
                                    displayEmpty
                                    variant='filled'
                                    disableUnderline
                                    className='w-100 mt-2 txt-md'
                                    inputProps={{ name: 'type' }}
                                    onChange={updateDeploymentDetails}
                                    value={type}
                                >
                                    <MenuItem className='txt-md' value={'individual'}>Individual</MenuItem>
                                    <MenuItem className='txt-md' value={'group'}>Group</MenuItem>
                                    <MenuItem className='txt-md' value={'conditional'}>Conditional</MenuItem>
                                </Select>
                                <FormHelperText>{getErrorMessage('type')}</FormHelperText>
                            </div>
                            {type === 'conditional' && <div className='mb-4 col-lg-12 col-md-12 col-12 d-flex flex-wrap p-0'>
                                <div className='col-md-6 col-12'>
                                    <label className={`txt-md ${styles.txtColor}`}>Min <span className={styles.asteriskError}>*</span></label>
                                    <Select
                                        displayEmpty
                                        variant='filled'
                                        disableUnderline
                                        className='w-100 mt-2 txt-md'
                                        inputProps={{ name: 'min' }}
                                        onChange={updateDeploymentDetails}
                                    >
                                        {(FilterDuplicateVersion(optionReleases) || [])?.map(item => <MenuItem key={item?.id} className='txt-md' value={item}>{item?.version}</MenuItem>)}
                                    </Select>
                                    <FormHelperText>{getErrorMessage('min')}</FormHelperText>
                                </div>
                                <div className='col-md-6 col-12'>
                                    <label className={`txt-md ${styles.txtColor}`}>Max <span className={styles.asteriskError}>*</span></label>
                                    <Select
                                        displayEmpty
                                        variant='filled'
                                        disableUnderline
                                        className='w-100 mt-2 txt-md'
                                        inputProps={{ name: 'max' }}
                                        onChange={updateDeploymentDetails}
                                    >
                                        {([...FilterDuplicateVersion(optionReleases)?.filter(item => Number(item?.version) >= min?.version)] || [])?.map(item => <MenuItem key={item?.id} className='txt-md' value={item}>{item?.version}</MenuItem>)}
                                    </Select>
                                    <FormHelperText>{getErrorMessage('max')}</FormHelperText>
                                </div>

                            </div>}
                            {type !== 'group' && <div className=' mb-4 col-lg-12 col-md-12 col-12'>
                                <label className={`txt-md ${styles.txtColor}`}>Nhubs <span className={styles.asteriskError}>*</span></label>
                                <AutoComplete filter_key={'rpi_id'} handleplaceholder={handleplaceholder} value={inputValue || []} options={type === "individual" ? optionNhub : (min && max) ? optionCondition : []} getOptionLabel={(option) => option.rpi_id} noOptionsText='Nhub not Found' onChange={onSelection} displayKey='rpi_id' />
                                <FormHelperText>{getErrorMessage('nhubs')}</FormHelperText>
                            </div>}

                            {type === 'group' && <div className=' mb-4 col-lg-12 col-md-12 col-12'>
                                <label className={`txt-md ${styles.txtColor}`}>Groups <span className={styles.asteriskError}>*</span></label>
                                <AutoComplete filter_key={'group_name'} handleplaceholder={handleplaceholder} value={inputValue || []} options={optionGroups} getOptionLabel={(option) => option.group_name} noOptionsText='Group not Found' onChange={onSelection} displayKey='group_name' />
                                <FormHelperText>{getErrorMessage('nhubs')}</FormHelperText>
                            </div>}
                            <div className='d-flex flex-wrap'>
                                <div className='mb-4 col-lg-12 col-md-12 col-12'>
                                    <label className={`txt-md ${styles.txtColor}`}>Select a Release Name to deploy <span className={styles.asteriskError}>*</span></label>
                                    <div className='d-flex align-items-center'>
                                        <Select
                                            displayEmpty
                                            variant='filled'
                                            disableUnderline
                                            className='w-100 mt-2 txt-md'
                                            inputProps={{ name: 'release' }}
                                            onChange={updateDeploymentDetails}
                                            value={deploymentDetails['release']}
                                        >
                                            {(filteredVersions || [])?.map(item => <MenuItem key={item?.id} className='txt-md' value={item?.name}>{item?.name}</MenuItem>)}
                                        </Select>
                                        <Tooltip className='mx-2 mt-2' placement={screenSize <= 991 ? "bottom" : "right"} title="The deployment will skip any devices in the group that are already on the target Release version, or that have an incompatible device type" >
                                            <Infoicon />
                                        </Tooltip>
                                    </div>
                                    <FormHelperText>{getErrorMessage('release')}</FormHelperText>
                                </div>
                            </div>
                            <div className='d-flex flex-wrap'>
                                <div className='mb-4 col-lg-12 col-md-12 col-12'>
                                    <label className={`txt-md ${styles.txtColor}`}>Select a start date and time <span className={styles.asteriskError}>*</span></label>
                                    <div className='d-flex flex-wrap'>
                                        <div className='col-12 p-0 d-flex align-items-center'>
                                            <div className='col d-flex flex-wrap p-0'>
                                                <div className='pr-sm-0 col-lg-6 col-md-6 col-12 pl-0 p-0'>
                                                    <DatePicker className='mt-2' inputProps={{ className: 'txt-md', name: 'date' }} value={date || null} onChange={(date) => handleDateChange(date)} />
                                                </div>
                                                <div className='pr-0 pl-md-3 col-lg-6 col-md-6 col-12 pl-0'>
                                                    <TimePicker disabled={!date ? true : false} style={{ width: "100%" }} className='mt-2' inputProps={{ className: 'txt-md', name: 'date' }} value={date || null} onChange={(date) => handleDateChange(date, true)} />
                                                    {isInvalidTime && <span style={{ fontSize: ".7rem", color: "red" }}>Invalid Time </span>}
                                                </div>
                                            </div>
                                            <Tooltip className='mx-2 mt-2' placement={screenSize <= 991 ? "bottom" : "right"} title="This time is relative to the server only – each device’s time zone will not be taken into account. Devices across different time zones will receive the update at the same time" >
                                                <Infoicon />
                                            </Tooltip>
                                        </div>
                                        <FormHelperText>{getErrorMessage('date')}</FormHelperText>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-row col-lg-12 col-md-12 col-12 pl-0'>
                                <div className='col-lg-6 col-md-6 col-6 mb-4 '>
                                    <label className={`txt-md ${styles.txtColor}`}>Retry times<span className={styles.asteriskError}>*</span></label>
                                    <TextField variant="filled" value={retryData} className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'retry' }} type='number' fullWidth placeholder="Retry times" onChange={handleRadioCheck} helperText={getErrorMessage('retry')} />

                                </div>
                                <div className='d-flex col-lg-6 col-md-6 col-6 pt-3 pl-0 align-items-center'>
                                    <Radio color='primary' value={defaultRetry} sx={{ '&.MuiIconButton-label': { color: "primary" } }} inputProps={{ name: 'retry' }} checked={radioChecked} onChange={handleRadio} />
                                    <span className={`txt-md mb-4 mt-4 ${styles.txtColor}`}>Save as default</span>
                                </div>
                            </div>

                        </div>

                        <div className='text-center mt-3'>
                            <Button variant="outlined" color="primary" className='px-4 mr-3'><span className='txt-primary' onClick={() => history.push(`/deployment?tab=${tab}`)}>Cancel</span></Button>
                            <Button variant="contained" color="primary" className='px-4' onClick={() => checkAlreadyLinkedNHUB(deploymentDetails?.type)}><img src={submitIcon} className='mr-2' /><span className='txt-white txt-light'>Create Deployment</span></Button>
                        </div>
                    </div>

                </div>
            </div>
            {confirmDialog && <ConfirmDialog onClose={onConfirmDialogClose} open={confirmDialog['open']} onConfirm={onConfirmDialog} message={deploymentDetails.type === "group" ? confirmDialog['message'] + effectedNhub + "\nimpacted Group(s):" + impacted : confirmDialog['message'] + effectedNhub} />}
            <Snackbar open={showSnackBar} message={snackbarContent?.message || ''} className={snackbarContent?.class || ''} autoHideDuration={4000} closeSnackBar={setShowSnackBar} />
        </>
    )
}

export default CreateDeployment