import { useParams, useHistory } from "react-router-dom"
import styles from './group-details.module.scss'
import { useEffect, useState, useContext } from 'react';

import AuthServices from 'api/services/auth-services';
import { GlobalContext, actions } from 'context';
import { format, isValid } from 'date-fns'
import NHubCard from '../../nhub/nhub-card/nhub-card';
import edit from 'assets/images/icon/edit.svg';
import deleteIcon from 'assets/images/icon/delete-icon.svg';
import { Tooltip } from "@material-ui/core";
import { ConfirmDialog, Snackbar } from "components";
import Header from "app/header/header";
import CreateGroup from "../create-group/create-group";

const GropuDetails = () => {

    const history = useHistory()
    const id = new URLSearchParams(history.location.search).get("id")
    const { dispatch } = useContext(GlobalContext);
    const [openPopup, setOpenPopup] = useState(false)
    const [nhubList, setNhubList] = useState([])
    const [nhubListCopy, setNhubListCopy] = useState([])
    const [groupDetail, setGroupDetail] = useState('')
    const [showSnackBar, setShowSnackBar] = useState(false)
    const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });
    const HandleEditGroupDetail = () => {
        history.push(`/edit-group?id=${id}`)
    }
    const HandleDeleteGroup = async () => {
        setOpenPopup(false)
        storeHandler(actions.SET_TAB_VALUE,1)
        storeHandler(actions.SHOW_LOADER, true)
        try {
            const { data } = await AuthServices.deleteGroup(id)
            setSnackBarContent({ message: "Group deleted successfully", class: "success" })
            setShowSnackBar(true)
            storeHandler(actions.SHOW_LOADER, false)
            setTimeout(() => {
                history.push('/?tab=group')
            }, 1000)
        } catch (err) {
            setSnackBarContent({ message: err?.data?.message || "Some Error Accure", class: "error" })
            setShowSnackBar(true)
            storeHandler(actions.SHOW_LOADER, false)
        }
    }

    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = async () => {
        storeHandler(actions.SHOW_LOADER, true)
        try {
            const { data } = await AuthServices.detailGroup(id)
            setNhubList(data?.nhubs)
            setNhubListCopy(data?.nhubs)
            setGroupDetail(data?.group)
            storeHandler(actions.SHOW_LOADER, false)
        } catch (err) {
            storeHandler(actions.SHOW_LOADER, false)
        }
    }

    const search = (search_String) => {
        let filteredData = nhubList.filter(({ rpi_id, seniors }) => {
            const name = seniors[0]?.name.toLowerCase() || "";
            const rpiId = rpi_id.toLowerCase() || ""
            const searchStringLower = search_String.toLowerCase();
            return checkMatch(searchStringLower, name) || checkMatch(searchStringLower, rpiId);
        });
        setNhubListCopy(filteredData)
    }

    const checkMatch = (search, data) => {
        let start = 0;
        if (data.startsWith(search))
            return true;
        while (start < search.length) {
            let ind = data.indexOf(' ', start);
            if (ind === -1) {
                return false;
            }
            if (data.substring(ind + 1).startsWith(search)) {
                return true;
            }
            start = ind + 1;
        }
        return false;
    }

    const storeHandler = (type, payload) => dispatch({ type, payload })

    return (
        <>
            <Header searchBar={true} searchFn={search} placeholder={"Search by senior name"} />
            <div className="d-flex body_container flex-grow-1">
                <div className="col-12 flex-grow-1 d-flex">
                    <div className={`flex-grow-1 d-flex flex-column mb-5`}>
                        <div className="d-flex flex-wrap justify-content-between">
                            <div className="flex-grow-1 d-flex justify-content-between col-7 flex-wrap my-2 px-0">
                                <div className="d-flex flex-column col-7  px-0 mb-3">
                                    <span className="txt-grey-drk txt-md mb-2"> Group ID</span>
                                    <div className="d-flex align-items-center">{groupDetail?.group_id}</div>
                                </div>
                                <div className="d-flex flex-column  col-5 mb-3">
                                    <span className="txt-grey-drk txt-md mb-2">Group Name</span>
                                    <span className="txt-primary txt-lg">{groupDetail?.group_name}</span>
                                </div>
                            </div>

                            <div className="d-flex" style={{ gap: "1rem" }}>
                                <Tooltip title="Edit Group">
                                    <span className='d-flex align-items-center cursor' onClick={HandleEditGroupDetail}> <img src={edit} alt="addIcon" className={`${styles.addIcon}`} /></span>
                                </Tooltip>
                                <Tooltip title="Delete Group">
                                    <span className='d-flex align-items-center cursor' onClick={() => setOpenPopup(true)}> <img src={deleteIcon} alt="deleteIcon" className={`${styles.addIcon}`} /></span>
                                </Tooltip>
                            </div>

                            <div className="flex-grow-1 d-flex justify-content-between col-7 flex-wrap my-2 px-0">
                                <div className="d-flex flex-column col-7  px-0 mb-3">
                                    <span className="txt-grey-drk txt-md mb-2">Registered Date</span>
                                    <div className="d-flex align-items-center"><span className="txt-primary txt-lg">{isValid(new Date(groupDetail?.register_date)) ? format(new Date(groupDetail?.register_date), 'MM-dd-yyyy') : '-'}</span> </div>
                                </div>
                            </div>

                        </div>
                        <div className="">
                            <div className="d-flex justify-content-between border-bottom mb-3">
                                <p className="">Nhubs</p>
                            </div>
                            {nhubListCopy && !!nhubListCopy.length && <>
                                <div className='d-flex flex-wrap'>
                                    {nhubListCopy.map((val, index) => (
                                        <div className='col-12 col-md-6 col-lg-3 mb-3' key={index}>
                                            <NHubCard isDelete={true} data={val} />
                                        </div>
                                    ))}
                                </div></>}

                            {nhubListCopy && !nhubListCopy.length  && (
                                <div className='text-center mt-5 pt-5'>
                                    <div className='col-12'>
                                        No accounts found.
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmDialog isPrompt={false} open={openPopup} onClose={setOpenPopup} onConfirm={HandleDeleteGroup} message={`Are you Sure you want to delete Account?`} />
            <Snackbar open={showSnackBar} message={snackbarContent?.message || ''} className={snackbarContent?.class || ''} autoHideDuration={3000} closeSnackBar={setShowSnackBar} />
        </>

    )
}

export default GropuDetails