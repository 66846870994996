import React from "react";
import ReleaseCard from './release-card/release-card';
import addIcon from 'assets/images/icon/add-icon.svg';
import CreateRelease from "./create-release/create-release";
import { differenceInCalendarDays } from 'date-fns';
import { useEffect, useState, useContext } from 'react';
import styles from './release.module.scss'

import AuthServices from 'api/services/auth-services';
import { GlobalContext, actions } from 'context';
import moment from "moment";
import Header from "app/header/header";
import { useHistory } from "react-router-dom";

function Release() {

    const history = useHistory()
    const { dispatch } = useContext(GlobalContext);
    const [groupData, setGroupData] = useState([])
    const [todaysData, setTodaysData] = useState([]);
    const [yesterdaysData, setYesterdaysData] = useState([]);
    const [oldData, setOldData] = useState([]);
    const [releaseVersion, setReleaseVersion] = useState([]);
    const [version , setVersion] = useState("")
    const [patchVersion , setPatchVersion] = useState({})

    useEffect(() => {
        getRelease()
    }, [])
    
    const getRelease = async () => {
        storeHandler(actions.SHOW_LOADER, true)
        try {
            const resp = await AuthServices.getRelease()
            const { releases , version , patch_version} = resp || {}
            filterData(releases)
            setGroupData(releases)
            setVersion(version)
            setPatchVersion(patch_version)
            storeHandler(actions.SHOW_LOADER, false)
        } catch (err) {
            storeHandler(actions.SHOW_LOADER, false)
        }
    }

    const storeHandler = (type, payload) => dispatch({ type, payload })

    const filterData = (data) => {
        const todayList = data.filter(({ registered_date }) => differenceInCalendarDays(new Date(), new Date(registered_date?.$date)) === 0)
        const yesterdayList = data.filter(({ registered_date }) => differenceInCalendarDays(new Date(), new Date(registered_date?.$date)) === 1)
        const oldList = data.filter(({ registered_date }) => differenceInCalendarDays(new Date(), new Date(registered_date?.$date)) > 1)
        let versionList = []
        data?.map((el) => {
            if (el.type === "release") {
                versionList.push(el.version)
            }
        })
        const handleOldDate = (list) => {
            let dateWiseList = {}
            list?.map((el) => {
                let date = moment(el?.registered_date?.$date).format("MMM DD, yyyy")
                if (dateWiseList[date] === undefined) {
                    dateWiseList[date] = [el]
                } else {
                    dateWiseList[date].push(el)
                }
            })
            setOldData(dateWiseList)
        }
        setReleaseVersion(versionList)
        setTodaysData(todayList)
        setYesterdaysData(yesterdayList)
        handleOldDate(oldList)

    }

    const search = (search_String) => {
        let filteredData = groupData.filter(({ name }) => {
            const _name = name.toLowerCase() || "";
            const searchStringLower = search_String.toLowerCase();
            return checkMatch(searchStringLower, _name);
        });
        filterData(filteredData)
    }

    const checkMatch = (search, data) => {
        let start = 0;
        if (data.startsWith(search))
            return true;
        while (start < search.length) {
            let ind = data.indexOf(' ', start);
            if (ind === -1) {
                return false;
            }
            if (data.substring(ind + 1).startsWith(search)) {
                return true;
            }
            start = ind + 1;
        }
        return false;
    }


    const createRelease = () => {
        history.push("/create-release")
    }

    return (
        <>
            <Header searchBar={true} searchFn={search} placeholder={"Search by release name"} />
            <div className="body_container flex-grow-1">
                <div className="d-flex justify-content-end mt-4">
                    {<span className='d-flex align-items-center cursor mr-3' onClick={createRelease}> <img src={addIcon} alt="add" className={`${styles.addIcon} mr-2`} />Create Release</span>}
                </div>
                {todaysData && !!todaysData.length && <><div className='border-bottom mb-3 pb-2 mx-3 mt-4'><span>Today</span></div>

                    <div className='d-flex flex-wrap'>
                        {todaysData.map((val, index) => (
                            <div className='col-12 col-md-6 col-lg-2 mb-3' key={index}>
                                <ReleaseCard data={val} />
                            </div>
                        ))}
                    </div></>}

                {yesterdaysData && !!yesterdaysData.length && <><div className='border-bottom mb-3 pb-2 mx-3 mt-4'><span>Yesterday</span></div>

                    <div className='d-flex flex-wrap'>
                        {yesterdaysData.map((val, index) => (
                            <div className='col-12 col-md-6 col-lg-2 mb-3' key={index}>
                                <ReleaseCard data={val} />
                            </div>
                        ))}
                    </div></>}

                {!!Object.keys(oldData).length && Object.keys(oldData).map((key) => {
                    return (
                        <>
                            <div key={key} className='border-bottom mb-3 pb-2 mx-3 mt-4'><span>{key}</span></div>
                            <div className='d-flex flex-wrap'>
                                {oldData[key].map((val, index) => (
                                    <div className='col-12 col-md-6 col-lg-2 mb-3' key={index}>
                                        <ReleaseCard data={val} />
                                    </div>
                                ))}
                            </div>
                        </>
                    )
                })}

                {!todaysData.length && !yesterdaysData.length && !Object.keys(oldData).length && (
                    <div className='text-center mt-5 pt-5'>
                        <div className='col-12'>
                            No release found.
                        </div>
                    </div>
                )}


            </div>
        </>
    );
}

export default Release;
