import styles from './release-details.module.scss'
import { Button } from "components";
import submitIcon from 'assets/images/icon/submit-icon.svg';
import { GlobalContext, actions } from 'context';
import { useContext, useEffect, useState } from 'react';
import { format, isValid } from 'date-fns'
import moment from 'moment/moment';
import Header from 'app/header/header';
import AuthServices from 'api/services/auth-services';
import { useHistory, useParams } from 'react-router-dom';

const ReleaseDetails = () => {

    const history = useHistory()
    const id = new URLSearchParams(history.location.search).get("id")
    const { dispatch } = useContext(GlobalContext);
    const [releaseDetails , setReleaseDetails] = useState({})
    const { description, name, version, type, device_type, patch_version , registered_date } = releaseDetails || {}
    moment(registered_date).local();

    useEffect(() => {
        getRelease()
    } , [] )

    const getRelease = async () => {
        storeHandler(actions.SHOW_LOADER, true)
        try {
            const resp = await AuthServices.getRelease()
            const { releases } = resp || {}
            for(let x = 0 ; x < releases?.length ; x++){
                if(releases[x]['_id']['$oid'] === id){
                    setReleaseDetails(releases[x])
                    break
                }
            }
            storeHandler(actions.SHOW_LOADER, false)
        } catch (err) {
            storeHandler(actions.SHOW_LOADER, false)
        }
    }

    const storeHandler = (type, payload) => dispatch({ type, payload })

    return (
        <>
            <Header searchBar={false}/>
            <div className="d-flex body_container">
                <div className="col-lg-7 col-12 flex-grow-1 d-flex">
                    <div className={`${styles.detailsWrapper} flex-grow-1 p-3 d-flex flex-column`}>
                        <div className="d-flex flex-wrap border-bottom">
                            <div className="flex-grow-1 d-flex justify-content-between col-12 flex-wrap my-2 px-0">
                                <div className="d-flex flex-column   mb-3 col-6 px-0">
                                    <span className="txt-grey-drk txt-md mb-2">Release Name</span>
                                    <div className='d-flex align-items-center'><span className="txt-primary txt-lg">{name}</span> </div>
                                </div>
                                <div className="d-flex flex-column  mb-3  col-6 ">
                                    <span className="txt-grey-drk txt-md mb-2">Release Date</span>
                                    <span className="txt-primary txt-lg">{isValid(new Date(registered_date?.$date)) ? format(new Date(registered_date?.$date), 'MM-dd-yyyy') : '-'}</span>
                                </div>
                                <div className="d-flex flex-column  mb-3 col-6 px-0">
                                    <span className="txt-grey-drk txt-md mb-2">Device type</span>
                                    <span className="txt-primary txt-lg capitalize">{device_type}</span>

                                </div>
                                <div className="d-flex flex-column  mb-3  col-6 ">
                                    <span className="txt-grey-drk txt-md mb-2">Release Time</span>
                                    <span className="txt-primary txt-lg">{isValid(new Date(registered_date?.$date)) ? format(new Date(registered_date?.$date), 'hh:mm:ss a') : '-'}</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap flex-grow-1 pt-3">
                            <div className="col-5 border-right d-flex flex-column">
                                <div className="d-flex flex-column mb-4">
                                    <span className="txt-grey-drk txt-md mb-2">Release type </span>
                                    <span className="txt-primary txt-lg capitalize">{type}</span>
                                </div>
                                <div className='d-flex flex-column mb-4'>
                                    <span className="txt-grey-drk txt-md mb-2">Release Version </span>
                                    <span className="txt-primary txt-lg">{version}</span>
                                </div>
                                {type === "patch" &&
                                    <div className="d-flex flex-column mb-4">
                                        <span className="txt-grey-drk txt-md mb-2">Patch Version</span>
                                        <span className="txt-primary txt-lg">{patch_version || '-'}</span>
                                    </div>}
                            </div>
                            <div className="col-7">
                                <p className="txt-grey-drk mb-3 px-4">Release Contents</p>

                                <div className="px-4">
                                    <textarea disabled className={`${styles.textArea}`} value={description || ''} placeholder='Release Contents Will be Placed here'></textarea>
                                    {/* <div className='text-center mt-3'>
                                <Button variant="contained" color="primary" className='px-4'><img src={submitIcon} className='mr-2' /><span className='txt-white txt-light'>Download</span></Button>
                            </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReleaseDetails