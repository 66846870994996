import {
    LOG_IN,
    SHOW_LOADER,
    SET_USER_DETAILS,
    SET_RELEASE_DETAILS,
    SET_GROUP_DETAILS,
    SET_TAB_VALUE
} from './actions';

const initialState = {
    isLoggedIn: false,
    user_name : "",
    showLoader: false,
    userData: {},
    releaseDetails: {},
    groupDetails: {},
    tabVal:0
};

const reducer = (state, { type, payload }) => {
    switch (type) {
        case LOG_IN: {
            return {
                ...state,
                isLoggedIn: payload.login,
                user_name:payload.name
            }
        }
        case SHOW_LOADER: {
            return {
                ...state,
                showLoader: payload
            }
        }
        case SET_USER_DETAILS: {
            return {
                ...state,
                userData: { ...payload }
            }
        }
        case SET_RELEASE_DETAILS: {
            return {
                ...state,
                releaseDetails: { ...payload }
            }
        }
        case SET_GROUP_DETAILS: {
            return {
                ...state,
                groupDetails: { ...payload }
            }
        }
        case SET_TAB_VALUE:{
            return{
                ...state,
                tabVal:payload
            }
        }
        default:
            throw new Error();

    }
};

export { reducer, initialState }