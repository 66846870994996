import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment, IconButton, styled } from '@material-ui/core';
import BsSearch from 'assets/images/icon/search.svg';
import styles from "./SearchBar.scss";
import { useLocation } from 'react-router-dom';

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'green',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'red',
    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'green',
    },
  },
});

const SearchBar = ({ search , placeholder }) => {
  const [searchString, setSearchString] = useState('');

  let { pathname } = useLocation();
  const URL = pathname?.replace('/', '')?.split('/')[0]

  useEffect(()=>{
    setSearchString("")
  } , [URL])

  return (
    <TextField
      size="small"
      value={searchString}
      onChange={(e) => {
        setSearchString(e.target.value); 
        search(e.target.value);
      }}
      className='search-width search-input w-100'
      variant="outlined" 
      placeholder={placeholder || 'Search by senior name'}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <img src={BsSearch} className="search-icon" alt="searchBar"/>
          </InputAdornment>
        )
      }}
    />
  );
};

export default SearchBar;
