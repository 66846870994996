import styles from './header.module.scss';
import avatar from 'assets/images/icon/default.svg';
import user from 'assets/images/icon/user.svg';
import logout from 'assets/images/icon/logout.svg';
import { Menu, MenuItem, ConfirmDialog } from 'components'; 
import { useEffect, useState, useContext } from 'react';
import AuthServices from 'api/services/auth-services';
import { GlobalContext, actions } from 'context';
import { Link, useLocation } from 'react-router-dom';
import SearchBar from 'components/search-bar/SearchBar';

const Header = ({searchBar , searchFn , placeholder}) => {

    const { state:{ user_name } , dispatch } = useContext(GlobalContext);
     const name = user_name || localStorage.getItem("name") || "Guest"
    const [profileData, setProfile] = useState({});
    const { profile } = profileData || {};
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    let { pathname } = useLocation();
    const URL = pathname?.replace('/', '')?.split('/')[0]


    const storeHandler = (type, payload) => dispatch({ type, payload })



    const logOut = () => {
        localStorage.clear();
        window.location.reload()
    }

    const handleClose = () => {
        setAnchorEl2(null);
        setOpenDialog(false);
    };

    const onConfirm = () => {
        logOut()
        setOpenPopup(false);
    }

    return (
        <div className={`${styles.headerWrapper} py-sm-2 p-2 d-flex justify-content-between flex-wrap`}>
            <div className='d-flex align-items-center flex-grow-1 justify-content-between'>
                <div>
                    <span className='txt-xl capitalize txt-primary-drk txt-light '>{URL || 'Nhub'}</span>
                </div>
                <div className={`${styles['box-parent-dash']} ${searchBar && styles['search-box-box']} d-flex`}>
                {searchBar && <SearchBar search={searchFn} placeholder={placeholder} /> } 
                <div className={`${styles.avatar} cursor`}>
                    <img src={profile || avatar} alt="dashboard-icon" onClick={(event) => setAnchorEl2(event.currentTarget)} />
                </div>
                </div>
                <Menu
                    anchorEl={anchorEl2}
                    keepMounted
                    open={Boolean(anchorEl2)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    getContentAnchorEl={null}
                    className='dark'
                >
                    <MenuItem className='logout cursor-default'> <img src={user} width="16px" alt="user" /><span className='txt-md ml-2 capitalize'>{`${name}`}</span></MenuItem>
                    <MenuItem onClick={() => setOpenPopup(true)} className='logout'> <img src={logout} width="16px" alt="logout" /><span className='txt-md ml-2'>Logout</span></MenuItem>
                </Menu>
            </div>
            <ConfirmDialog isPrompt={false} open={openPopup} onClose={setOpenPopup} onConfirm={onConfirm} message={`Are you Sure you want to logout?`} />
        </div>
    )
}

export default Header