import React from "react";
import DeployCard from './deploy-card/deploy-card';

import { differenceInCalendarDays } from 'date-fns';
import { useEffect, useState } from 'react';
import moment from "moment";

function Deploy({ data,tab }) {
    const [todaysData, setTodaysData] = useState([]);
    const [yesterdaysData, setYesterdaysData] = useState([]);
    const [oldData, setOldData] = useState({});
    const handleOldDate = (list) => {
        let dateWiseList = {}
        list?.map((el) =>{
            let date = moment(el?.registered_date).format("MMM DD, yyyy")
            if(dateWiseList[date] === undefined){
                dateWiseList[date] = [el]
            }else{
                dateWiseList[date].push(el)
            }
        })
        setOldData(dateWiseList)
    }

    useEffect(() => {
        const todayList = data.filter(({ registered_date }) => differenceInCalendarDays(new Date(), new Date(registered_date)) === 0 )
        const yesterdayList = data.filter(({ registered_date }) => differenceInCalendarDays(new Date(), new Date(registered_date)) === 1)
        const oldList = data.filter(({ registered_date }) => differenceInCalendarDays(new Date(), new Date(registered_date)) > 1)
        setTodaysData(todayList)
        setYesterdaysData(yesterdayList)
        handleOldDate(oldList)

    }, [data])

    if(!data.length && tab === 0) {
        return <div className="text-center mt-5 pt-5">There are no active deployment data</div>
    }else if(!data.length && tab === 1){
        return <div className="text-center mt-5 pt-5">There are no Scheduled deployment data</div>
    }else if(!data.length && tab === 2){
        return <div className="text-center mt-5 pt-5">There are no Finished deployment data</div>
    }



    return (
        <>
            <div className="body_container flex-grow-1">

                {todaysData && !!todaysData.length && <><div className='border-bottom mb-3 pb-2 mx-3 mt-4'><span>Today</span></div>

                    <div className='d-flex flex-wrap'>
                        {todaysData.map((val, index) => (
                            <div className='col-12 col-md-6 col-lg-3 mb-3' key={index}>
                                <DeployCard data={val} />
                            </div>
                        ))}
                    </div></>}

                {yesterdaysData && !!yesterdaysData.length && <><div className='border-bottom mb-3 pb-2 mx-3 mt-4'><span>Yesterday</span></div>

                    <div className='d-flex flex-wrap'>
                        {yesterdaysData.map((val, index) => (
                            <div className='col-12 col-md-6 col-lg-3 mb-3' key={index}>
                                <DeployCard data={val} />
                            </div>
                        ))}
                    </div></>}

                    {!!Object.keys(oldData).length && Object.keys(oldData).map((key) => {
                        return (
                            <>
                            <div key={key} className='border-bottom mb-3 pb-2 mx-3 mt-4'><span>{key}</span></div>
                                <div className='d-flex flex-wrap'>
                                    {oldData[key].map((val, index) => (
                                        <div className='col-12 col-md-6 col-lg-3 mb-3' key={index}>
                                            <DeployCard data={val} />
                                        </div>
                                    ))}
                            </div>
                            </>
                        )
                    })}


            </div>
        </>
    );
}

export default Deploy;
