import React from 'react';
import { Button } from 'components';
import {
    Dialog,
    DialogActions,
    DialogContentText,
    DialogContent,
} from '@material-ui/core';
import styles from './confirm-popup.module.scss';

const ConfirmDialog = ({ onClose, open, onConfirm, message, isPrompt}) => (
    <Dialog open={open} className={styles.confirmDialog} maxWidth={'sm'}>
        <DialogContent>
            <DialogContentText className={styles.dialogContent}>
                <p className='txt-grey-lt txt-lg text-center my-0 txt-medium'>{message}</p>
            </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.footerSec}>
            {isPrompt ?
                <Button variant="contained" color="primary" onClick={onConfirm} className={'mx-0'}>
                    Ok
                </Button> :
                <>
                    <Button variant="contained" color="primary" onClick={onConfirm} disableElevation={true} className={styles.errorBtn}>
                        Yes
                    </Button>
                    <Button variant="outlined" color="primary" onClick={() => onClose(false)}>
                        No
                    </Button>
                </>
            }

        </DialogActions>
    </Dialog>
);

export default ConfirmDialog;
