import { TabPanel, Tab, Tabs } from 'components';
import { useEffect, useState, useContext } from 'react';
import Deploy from './deploy/deploy';
import styles from './deployment.module.scss'
import CreateDeployment from './create-deploy/create-deployment';
import addIcon from 'assets/images/icon/add-icon.svg';
import AuthServices from 'api/services/auth-services';
import { GlobalContext, actions } from 'context';
import Header from 'app/header/header';
import { useHistory, useParams } from 'react-router-dom';

const Deployment = () => {

    const tabs = {
        active:0,
        scheduled:1,
        finished:2
    }
    const getSearchParam = (url) => new URLSearchParams(url)
    const history = useHistory()
    const { status } = useParams()
    const { dispatch } = useContext(GlobalContext);
    const [tabIndex, setTabValue] = useState(0);
    const [deployments, setDeployments] = useState({ active: [], finished: [], scheduled: [] })
    const { active, finished, scheduled } = deployments || { active: [], finished: [], scheduled: [] }

    useEffect(() => {
        getDeployments()
    }, [])

    useEffect(() => {
        if(getSearchParam(history.location.search).get("tab") === null) history.push("?tab=active")
        else{
            if(getSearchParam(history.location.search).get("tab") === "active") setTabValue(0)
            else if(getSearchParam(history.location.search).get("tab") === "scheduled" ) setTabValue(1)
            else if(getSearchParam(history.location.search).get("tab") === "finished" ) setTabValue(2)
        }
    }, [history.location.search] )
    
    const getDeployments = async () => {    

        storeHandler(actions.SHOW_LOADER, true)

        const data = await AuthServices.getAllDeployments()
        setDeployments(data || { active: [], finished: [], scheduled: [] })
        storeHandler(actions.SHOW_LOADER, false)
    }

    const handleTabChange = (event, newValue) => {
        newValue === 0 && history.push('?tab=active')
        newValue === 1 && history.push('?tab=scheduled')
        newValue === 2 && history.push('?tab=finished')
    };

    const createDeployment = () => {
        history.push(`/create-deployment?tab=${getSearchParam(history.location.search).get('tab')}`)
    }

    const storeHandler = (type, payload) => dispatch({ type, payload })

    return (
        <>
            <Header searchBar={false} />
            <div className="body_container flex-grow-1">
                <div className='d-flex justify-content-between'>
                    <Tabs value={tabIndex} onChange={handleTabChange} variant='scrollable' indicatorColor='primary' className='btn-tabs my-4 indicator-none px-3'>
                        <Tab className='fixed-label-width' label={'Active'} id={`tab 0`} />
                        <Tab className='fixed-label-width' label={'Scheduled'} id={`tab 1`} />
                        <Tab className='fixed-label-width' label={'Finished'} id={`tab 2   `} />
                    </Tabs>
                    {<span className='d-flex align-items-center cursor mr-3' onClick={createDeployment}> <img src={addIcon} alt="add" className={`${styles.addIcon} mr-2`} />Create Deployment</span>}

                </div>


                <TabPanel value={tabIndex} index={0}>
                    <Deploy data={active || []} tab={tabIndex} />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <Deploy data={scheduled || []} tab={tabIndex} />
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <Deploy data={finished || []} tab={tabIndex} />
                </TabPanel>
            </div>
        </>
    )
}

export default Deployment