/* eslint-disable no-use-before-define */

import React, { useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function CheckboxesTags(props) {

    const searchData = (data, input) => {
        return data?.filter(el => {
            let deviceID = el?.device_id?.toLowerCase()
            let name = el?.name?.toLowerCase()
            let rpi_id = el?.rpi_id?.toLowerCase()
            let group_name = el?.group_name?.toLowerCase()
            let seniour = el?.seniors?.map(x => {
                if ( typeof x === 'object' && !Array.isArray(x) && x !== null ) {
                    //This is object
                    return x?.name?.toLowerCase()
                }
                if(typeof x === 'string'){
                    //This is string
                    return x.toLowerCase()
                }
                if(Array.isArray(x)){
                    //This is Array
                }
            } )
            return deviceID?.match(input) || rpi_id?.match(input) || name?.match(input) || group_name?.match(input) || seniour?.some(item => item.match(input))
        })
    }


    return (
        <Autocomplete
            {...props}
            multiple
            id="checkboxes-tags-demo"
            disableCloseOnSelect
            limitTags={2}
            // getOptionLabel={(option)=>option.filter_key}
            filterOptions={ (data,input) => searchData(data,input.inputValue.toLowerCase()) }
            getOptionSelected={(option, value) => value[props?.filter_key] === option[props?.filter_key]}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option[props?.filter_key] || ""}
                </React.Fragment>
            )}
            renderInput={(params) => (
                <TextField {...params} InputProps={{ ...params.InputProps, disableUnderline: true }} variant="filled" className={'withoutLabel mt-2'} type='text' fullWidth placeholder={props.handleplaceholder && "Search"} />
            )}
        />
    );
}
