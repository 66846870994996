import { format, isValid } from "date-fns";
import React , {useContext, useState} from "react";
import { useHistory } from "react-router-dom";
// import { Card } from "../index";
import styles from "./group-card.module.scss";


const InfoCard = ({ data }) => {

    return (
        <div className={`${styles.senior_card} col-6 text-center`}>
            <span className="p-2 justify-content-center">{data}</span>
        </div>
    )
}

function GroupCard({ data }) {

    const history = useHistory()
    const { group_name, group_id, registered_date, nhub_devices, sensors , id  } = data || {}
    
    const navigate = () => {
        history.push(`/group?id=${id}`)
    }


    return (
        <div className={`${styles.nhub_card} px-3 pt-3 [b-1] h-100 cursor`} onClick={navigate}>
            <div>
                <div className={`${styles.nhub_card_header}`}>
                    <p className="mb-1 mt-0">{group_name}</p>
                </div>
                <div className={`${styles.nhub_card_date}`}>
                    <p className="mb-1 mt-0">{isValid(new Date(registered_date)) ? format(new Date(registered_date), 'MM-dd-yyyy hh:mm:ss a') : '-'}</p>
                </div>
            </div>
            <div className={`${styles.nhub_senior_con} my-2`}>
                {!!sensors?.length && <p>Sensors</p>}
                <div className={`${styles.nhub_senior_list} row`}>
                    {sensors?.map(item => <InfoCard data={item} />)}
                </div>
            </div>
            <div className={`${styles.nhub_senior_con} my-2`}>
                {!!nhub_devices?.length && <p>NHUB</p>}
                <div className={`${styles.nhub_senior_list} row`}>
                    {nhub_devices?.map(item => <InfoCard data={item} />)}
                </div>
            </div>
        </div>
    );
}

export default GroupCard;
