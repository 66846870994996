import httpClient from '../http-client';
import {
  LOGIN,
  GET_OTP,
  VERIFY_OTP,
  RESET_PASSWORD,
  GET_PROFILE,
  GET_NHUB_LIST,
  GET_NHUBS,
  GET_GROUPS,
  ADD_GROUP,
  NHUB_DETAIL,
  ADD_RELEASE,
  GET_RELEASE,
  GET_DEPLOYMENT_LISTS,
  ADD_DEPLOYMENT,
  ADD_CONDITIONAL_DEPLOYMENT,
  GET_DEPLOYMENTS,
  GET_DEPLOYMENT_DETAIL,
  GET_REPORT,
  SEND_EMAIL,
  DELETE_GROUP,
  NHUB_REMOVE,
  DETAILGROUP,
  EDITGROUP,
  GET_RELEASE_IMAGE,
  GET_PATCH_IMAGE,
  EDIT_DEPLOYMENTS
} from '../config';

const AuthServices = {

  login: (data) => httpClient.post(LOGIN, data).then((response) => response.data),
  getOtp: (data) => httpClient.post(GET_OTP, data).then((response) => response.data),
  verifyOtp: (data) => httpClient.post(VERIFY_OTP, data).then((response) => response.data),
  resetPassword: (data) => httpClient.post(RESET_PASSWORD, data).then((response) => response.data),


  getProfileData: () => httpClient.get(`${GET_PROFILE}`).then((response) => response.data),

  getNhubLists: () => httpClient.get(`${GET_NHUB_LIST}`).then((response) => response.data),
  getNhubs: () => httpClient.get(`${GET_NHUBS}`).then((response) => response.data),
  getGroups: () => httpClient.get(`${GET_GROUPS}`).then((response) => response.data),
  addGroup: (data) => httpClient.post(`${ADD_GROUP}`, data).then((response) => response.data),
  detailGroup: (id) => httpClient.get(`${DETAILGROUP}/${id}`).then((response) => response.data),
  editGroup: (data) => httpClient.post(`${EDITGROUP}`, data).then((response) => response.data),
  deleteGroup: (id) => httpClient.delete(`${DELETE_GROUP}/${id}`).then((response) => response.data),
  deleteNhub: (data) => httpClient.post(`${NHUB_REMOVE}`, data).then((response) => response.data),
  getNhubDetail: (id) =>  httpClient.get(`${NHUB_DETAIL}/${id}`).then((response) => response.data),

  addRelease: (data) => httpClient.post(`${ADD_RELEASE}`, data).then((response) => response.data),
  getRelease: () => httpClient.get(`${GET_RELEASE}`).then((response) => response.data),
  getReleaseImage:() =>httpClient.get(`${GET_RELEASE_IMAGE}`).then((response) => response.data),
  getPatchImage:() =>httpClient.get(`${GET_PATCH_IMAGE}`).then((response) => response.data),

  getDeploymentLists: () => httpClient.get(`${GET_DEPLOYMENT_LISTS}`).then((response) => response.data),
  addDeployment: (data) => httpClient.post(`${ADD_DEPLOYMENT}`, data).then((response) => response.data),
  addConditionalDeployment: (data) => httpClient.post(`${ADD_CONDITIONAL_DEPLOYMENT}`, data).then((response) => response.data),
  getAllDeployments: () => httpClient.get(`${GET_DEPLOYMENTS}`).then((response) => response.data),
  getDeploymentDetail: (id) =>  httpClient.get(`${GET_DEPLOYMENT_DETAIL}/${id}`).then((response) => response.data),
  editDeployment: (data) =>httpClient.post(`${EDIT_DEPLOYMENTS}`,data).then((response)=> response.data),  

  getReport: (type) =>  httpClient.get(`${GET_REPORT}/${type}`).then((response) => response.data),
  sendEmail: () =>  httpClient.get(`${SEND_EMAIL}`).then((response) => response.data),

};

export default AuthServices;
