import Nhub from './nhub/nhub';
import Group from './group/group';
import { TabPanel, Tab, Tabs } from 'components';
import { useEffect, useState } from 'react';
import addIcon from 'assets/images/icon/add-icon.svg';
import styles from './dashboard.module.scss'
import Header from 'app/header/header';
import { useHistory } from 'react-router-dom';

const Dashboard = () => {   
    const [tabIndex, setTabValue] = useState(0);
    const [searchString , setSearchString] = useState('');
    const getSearchParam = (url) => new URLSearchParams(url)
    const history = useHistory()

    const handleTabChange = (event, newValue) => {
        newValue === 0 ? history.push('?tab=nhub') : history.push('?tab=group')
    };

    const createGroup = () => {
        history.push('/create-group')
    }

    useEffect(() => {
        if(getSearchParam(history.location.search).get("tab") === null) history.push("?tab=nhub")
        else{
            if(getSearchParam(history.location.search).get("tab") === "nhub") setTabValue(0)
            else if(getSearchParam(history.location.search).get("tab") === "group" ) setTabValue(1)
        }
    }, [history.location.search] )

    const search = (search_String) => {
        setSearchString(search_String)
    }

    return (
        <>
        <Header searchBar={true} searchFn={search} placeholder={tabIndex === 1 ? "Search by group name" : "Search by senior name"}/>
        <div className="body_container flex-grow-1">
            <>
                <div className='d-flex justify-content-between'>
                    <Tabs value={tabIndex} onChange={handleTabChange} variant='scrollable' indicatorColor='primary' className='btn-tabs my-4 indicator-none px-3'>
                        <Tab className='fixed-label-width' label={'Nhub'} id={`tab 0`} />
                        <Tab className='fixed-label-width' label={'Group'} id={`tab 1`} />
                    </Tabs>
                    {tabIndex === 1 && <span className='d-flex align-items-center cursor mr-3' onClick={createGroup}> <img src={addIcon} alt="add" className={`${styles.addIcon} mr-2`} />Create Group</span>}
                </div>

                <TabPanel value={tabIndex} index={0}>
                    <Nhub searchString={searchString} />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <Group searchString={searchString} />
                </TabPanel>
            </>
        </div> 
        </>
        )
}

export default Dashboard