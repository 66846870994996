import React, { useEffect, useContext } from 'react'
import styles from './release-card.module.scss';
import { useHistory } from "react-router-dom";
import { format, isValid } from 'date-fns'
import { GlobalContext, actions } from 'context';
import moment from 'moment/moment';

function ReleaseCard({ data }) {
  const { registered_date, version, name , patch_version, _id } = data || {}
  const {dispatch}=useContext(GlobalContext)
  let history = useHistory();

  useEffect(() => {
    storeHandler(actions.SET_RELEASE_DETAILS, data)
  },[data])

  const navigate = () => {
    storeHandler(actions.SET_RELEASE_DETAILS, data)
    history.push(`/release-details/?id=${_id['$oid']}`);
  }

  const storeHandler = (type, payload) => dispatch({ type, payload })
  moment(registered_date).local();

  return (
    <div className={`${styles.release_card} px-2 py-3 h-100 cursor`} onClick={navigate}>
      <div className={`${styles.release_card_header}`}>
        <p className='mb-1 mt-0'>{name}</p>
      </div>
      <div className={`${styles.release_card_date}`}>
        <p className='mb-1 mt-0'>{isValid(new Date(registered_date?.$date)) ? format(new Date(registered_date?.$date), 'MM-dd-yyyy') : '-'}</p>
      </div>
      <div className={`${styles.release_ver_no} mb-1`}>
        <span className='mr-3'>Release Version</span>
        <span className={``}>{version}</span>
      </div>
      {patch_version && (
        <div className={`${styles.release_ver_no}`}>
          <span className='mr-3'>Patch Version</span>
          <span className={``}>{patch_version}</span>
        </div>
      )}
      
    </div>
  )
}

export default ReleaseCard