// Login APIs
export const LOGIN = '/login/admin';
export const GET_OTP = '/forgot/admin';
export const VERIFY_OTP = '/verify/admin';
export const RESET_PASSWORD = '/reset/admin';

// Profile APIs
export const GET_PROFILE = '/get/profile/member';


// NHUB APIs

export const GET_NHUB_LIST = '/list/rpi/group';
export const GET_NHUBS = '/list/rpi/device';
export const GET_GROUPS = '/list/groups';
export const ADD_GROUP = '/nhubs/add/group'
export const NHUB_DETAIL = '/rpi/device'

//Group APIs
export const DELETE_GROUP = '/delete/group'
export const DETAILGROUP = "/detail/group"
export const EDITGROUP = "/edit/group"
export const NHUB_REMOVE = '/rpi/remove/device'


// Release APIs

export const ADD_RELEASE = '/release/create';
export const GET_RELEASE = '/release/get/all';
export const GET_RELEASE_IMAGE ='image/all/release';
export const GET_PATCH_IMAGE ='image/all/patch';

// Deployment APIs

export const GET_DEPLOYMENT_LISTS = '/deploy/get/options'
export const ADD_DEPLOYMENT = '/deploy/create';
export const ADD_CONDITIONAL_DEPLOYMENT = '/deploy/conditional/options';
export const GET_DEPLOYMENTS = '/deploy/get/all'
export const GET_DEPLOYMENT_DETAIL = '/deploy/get'
export const EDIT_DEPLOYMENTS = '/deploy/edit';

// Reports APIs

export const GET_REPORT = '/reports'
export const SEND_EMAIL = '/reports/download/email'


