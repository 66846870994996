import NHubCard from './nhub-card/nhub-card';
import { differenceInCalendarDays } from 'date-fns';
import { useEffect, useState, useContext } from 'react';
import AuthServices from 'api/services/auth-services';
import { GlobalContext, actions } from 'context';
import moment from 'moment';

const Nhub = ({ searchString }) => {
    const { dispatch } = useContext(GlobalContext);

    const [nhubData, setNhubData] = useState([])
    const [todaysData, setTodaysData] = useState([]);
    const [yesterdaysData, setYesterdaysData] = useState([]);
    const [oldData, setOldData] = useState({});

    useEffect(() => {
        getNhubs()
    }, [])

    useEffect(() => {
        storeHandler(actions.SET_TAB_VALUE,0);
        let filteredData = nhubData.filter(({ seniors , rpi_id }) => {
            const name = seniors[0]?.name.toLowerCase() || "";
            const rpiId = rpi_id.toLowerCase() || ""
            const searchStringLower = searchString.toLowerCase();
            return checkMatch(searchStringLower, name) || checkMatch(searchStringLower, rpiId) ;
        });
        filterData(filteredData)
    }, [searchString])

    const checkMatch = (search, data) => {
        let start = 0;
        if (data.startsWith(search))
            return true;
        while (start < search.length) {
            let ind = data.indexOf(' ', start);
            if (ind === -1) {
                return false;
            }
            if (data.substring(ind + 1).startsWith(search)) {
                return true;
            }
            start = ind + 1;
        }
        return false;
    }

    const getNhubs = async () => {
        storeHandler(actions.SHOW_LOADER, true)
        try {
            const { data } = await AuthServices.getNhubs()
            const nhubData = data.map(item => ({ ...item }))
            filterData(nhubData)
            setNhubData(nhubData)
            storeHandler(actions.SHOW_LOADER, false)
        } catch (err) {
            storeHandler(actions.SHOW_LOADER, false)
        }
    }

    const handleOldDate = (list) => {
        let dateWiseList = {}
        list?.map((el) => {
            let date = moment(el?.date).format("MMM DD, yyyy")
            if (dateWiseList[date] === undefined) {
                dateWiseList[date] = [el]
            } else {
                dateWiseList[date].push(el)
            }
        })
        setOldData(dateWiseList)
    }

    const filterData = (data) => {

        const todayList = data.filter(({ date }) => differenceInCalendarDays(new Date(), new Date(date)) === 0)
        const yesterdayList = data.filter(({ date }) => differenceInCalendarDays(new Date(), new Date(date)) === 1)
        const oldList = data.filter(({ date }) => differenceInCalendarDays(new Date(), new Date(date)) > 1)

        setTodaysData(todayList)
        setYesterdaysData(yesterdayList)
        handleOldDate(oldList)
    }

    const storeHandler = (type, payload) => dispatch({ type, payload })


    return <>

        {todaysData && !!todaysData.length && <><div className='border-bottom mb-3 pb-2 mx-3 mt-4'><span>Today</span></div>

            <div className='d-flex flex-wrap'>
                {todaysData.map((val, index) => (
                    <div className='col-12 col-md-6 col-lg-3 mb-3' key={index}>
                        <NHubCard data={val} />
                    </div>
                ))}
            </div></>}

        {yesterdaysData && !!yesterdaysData.length && <><div className='border-bottom mb-3 pb-2 mx-3 mt-4'><span>Yesterday</span></div>

            <div className='d-flex flex-wrap'>
                {yesterdaysData.map((val, index) => (
                    <div className='col-12 col-md-6 col-lg-3 mb-3' key={index}>
                        <NHubCard data={val} />
                    </div>
                ))}
            </div></>}

        {!!Object.keys(oldData).length && Object.keys(oldData).map((key) => {
            return (
                <>
                    <div key={key + Math.random()} className='border-bottom mb-3 pb-2 mx-3 mt-4'><span>{key}</span></div>
                    <div className='d-flex flex-wrap'>
                        {oldData[key].map((val, index) => (
                            <div className='col-12 col-md-6 col-lg-3 mb-3' key={index}>
                                <NHubCard data={val} />
                            </div>
                        ))}
                    </div>
                </>
            )
        })}

        {!todaysData.length && !yesterdaysData.length && !Object.keys(oldData).length &&  (
            <div className='text-center mt-5 pt-5'>
                    <div className='col-12'>
                        No accounts found.
                    </div>
            </div>
        )}

    </>
}

export default Nhub