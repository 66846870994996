import moment from "moment";

export const isURLValid = (input) => {
  const urlRegex =
    /^[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?/;

  return urlRegex.test(input);
};

export const isEmailValid = (input) => {
  // const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/;
  // const emailRegex = /[a-z0-9._%+-]+@[a-z0-9]+\.[a-z]{2,15}/; // Regex for email Id don't accept +
  const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.\+]+\.[a-z]{2,15}/; // Regex for email Id accept +

  return emailRegex.test(input);
};

export const isPhonenoValid = (input) => {
  const phonenoRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}/;

  return phonenoRegex.test(input);
};

export const isPhonenNumValid = (input) => {
  // const phonenoRegex = /^[0-9]{3}[- ]?[0-9]{3}[- ]?[0-9]{4}$/;
  // return phonenoRegex.test(input);
  return /^[0-9]{10}$/.test(input);
};

export const isNumber = (input) => {
  return /^\d+$/.test(input);
}

export const mobileFormatter = (value) => {
  let newValue;
  if (value?.length >= 7)
    newValue = value?.slice(0, 3) + '.' + value?.slice(3, 6) + '.' + value?.slice(6, 10);
  else if (value?.length >= 4)
    newValue = value?.slice(0, 3) + '.' + value?.slice(3, 6);
  else
    newValue = value?.slice(0, 3);
  return newValue
}


export const timeDifference = (date1 , date2) => {
  if(!date1 || !date2) return false
  return moment(date1).diff(moment(date2 || new Date)) 
}