import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { actions, GlobalContext } from 'context';

// handle the private routes

function PrivateRoute({ component: Component, layout: Layout, ...rest }) {

    const checkIsLoggedIn = () => {

        if (!!localStorage.getItem("authToken") && !!localStorage.getItem("name") ) {
            return true
        }else{
            localStorage.clear()
            window.location.href = "/login"
        }
    }


    return <Route {...rest}> {checkIsLoggedIn() && <Layout><Component /></Layout> } </Route>
}

export default PrivateRoute;