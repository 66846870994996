import styles from './create-release.module.scss'
import { TextField, Select, MenuItem, Snackbar, Button } from 'components';
import FormHelperText from '@material-ui/core/FormHelperText';
import submitIcon from 'assets/images/icon/submit-icon.svg'
import AuthServices from 'api/services/auth-services';
import { GlobalContext, actions } from 'context';
import { useEffect, useState, useContext } from 'react';
import Header from 'app/header/header';
import { useHistory } from 'react-router-dom';

const errorMessages = {
    name: 'Enter the name',
    type: 'Select Type',
    version: 'Select version',
    device_type: 'Select Device type',
    description: 'Enter Release Details',
    file_url: 'Select the file',
    patch_version: 'Enter Patch Version',
}

const CreateRelease = () => {

    const history = useHistory()
    const { dispatch } = useContext(GlobalContext);
    const [versionList, setVersionList] = useState([])
    const [_version, setVersion] = useState('')
    const [patchVersion, setPatchVersion] = useState({})
    const [releaseDetails, setReleaseDetails] = useState({
        device_type: 'Hardware',
        type: "release",
        version: (Number(_version) + 1).toFixed(1) || (Number(1)).toFixed(1)
    });
    const [invalidForm, setInvalidForm] = useState(false);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });
    const [releaseList, setReleaseList] = useState([]);
    const [patchList, setPatchList] = useState([]);
    const { name, type, version, device_type, description, date, patch_version } = releaseDetails
    console.log(releaseDetails, "releaseDetails")

    useEffect(() => {
        setReleaseDetails({ ...releaseDetails, version: (Number(_version) + 1).toFixed(1) || (Number(1)).toFixed(1) })
    }, [_version])

    const getRelease = async () => {
        storeHandler(actions.SHOW_LOADER, true)
        try {
            const resp = await AuthServices.getRelease()
            const { releases, version, patch_version } = resp || {}
            let versionList = []
            if (releases && releases?.length) {
                releases?.forEach(el => {
                    if (el.type === "release") {
                        versionList.push(el.version)
                    }
                })
            }
            setVersionList(versionList)
            setVersion(version)
            setPatchVersion(patch_version)
            storeHandler(actions.SHOW_LOADER, false)
        } catch (err) {
            storeHandler(actions.SHOW_LOADER, false)
        }
    }


    const addRelease = async () => {

        storeHandler(actions.SHOW_LOADER, true)
        const payload = { ...releaseDetails }
        const formData = new FormData();
        for (let key in payload) {
            if (key.type === "release") {
                payload.delete(patch_version);
            }
            formData.append(key, payload[key])
        }

        try {
            const { message } = await AuthServices.addRelease(formData)
            const data = { message: message || 'Release created successfully', class: 'success' }
            setShowSnackBar(true)
            setSnackBarContent({ ...data })
            storeHandler(actions.SHOW_LOADER, false)
            setTimeout(() => {
                history.push('/release')
            }, 1000)
        } catch (err) {
            const data = { message: err?.data?.message || 'Error while creating the release. Please try after sometime', class: 'error' }
            setShowSnackBar(true)
            setSnackBarContent({ ...data })
            storeHandler(actions.SHOW_LOADER, false)
        }
    }
    const releaseImage = async () => {
        storeHandler(actions.SHOW_LOADER, true)
        try {
            const releaseImageList = await AuthServices.getReleaseImage();
            const newReleaseList = releaseImageList.data.filter((el) => {
                if (el?.size > 0) return el
            })
            setReleaseList(newReleaseList)
            storeHandler(actions.SHOW_LOADER, false)
        }
        catch (err) {
            storeHandler(actions.SHOW_LOADER, false)
        }
    }
    const patchImage = async () => {
        storeHandler(actions.SHOW_LOADER, true)
        try {
            const patchImageList = await AuthServices.getPatchImage();
            const newPatchList = patchImageList?.data.filter((el) => {
                if (el.size > 0) return el
            })
            setPatchList(newPatchList)
            storeHandler(actions.SHOW_LOADER, false)
        }
        catch (err) {
            storeHandler(actions.SHOW_LOADER, false)
        }

    }
    const saveDetails = () => {
        let isInvalidForm = false;
        let requiredFields = Object.keys(errorMessages);
        requiredFields.forEach(item => {
            if (!releaseDetails[item] && item !== 'file_url' && item !== 'patch_version') {
                isInvalidForm = true
                setInvalidForm(true);
                return
            }
        })
        if (!isInvalidForm) {
            addRelease()
        }
    }

    const updateReleaseDetails = (event) => {
        const { name, value } = event.target;
        if (name === "type" && value === "patch") {
            delete releaseDetails["version"]
            delete releaseDetails["patch_version"]
            delete releaseDetails["file_url"]
        }

        if (name === "type" && value === "release") {
            releaseDetails["version"] = (Number(_version) + 1).toFixed(1) || (Number(1)).toFixed(1)
            delete releaseDetails["patch_version"]
            delete releaseDetails["file_url"]
        }

        if (name === "version") {
            if (value && patchVersion[value]) {
                const checkAndIncVersion = (num) => {
                    if (!isNaN(num)) {
                        return (Number(patchVersion[value]) + .01).toFixed(2)
                    } else {
                        console.log("Version is Not A Valid Number")
                        return
                    }
                }
                releaseDetails['patch_version'] = checkAndIncVersion(patchVersion[value])
            } else {
                delete releaseDetails["patch_version"]
            }
        }

        setReleaseDetails({ ...releaseDetails, [name]: value })

    }

    const getErrorMessage = (field) => {
        let message = errorMessages[field]
        return invalidForm && !releaseDetails[field] && message
    }

    const storeHandler = (type, payload) => dispatch({ type, payload })

    useEffect(() => {
        getRelease()
        releaseImage();
        patchImage();
    }, [])


    return (
        <>

            <Header searchBar={false} />
            <div className="d-flex body_container flex-grow-1">
                <div className="col-lg-7 col-12 flex-grow-1 d-flex">
                    <div className={`${styles.detailsWrapper} flex-grow-1 p-4 d-flex flex-column`}>
                        <p className='mt-0'>Create Release</p>
                        <div className='d-flex flex-wrap px-0'>
                            <div className='col-lg-6 col-md-6 col-12 mb-4 pl-md-0'>
                                <label className={`txt-md ${styles.txtColor}`}>Name<span className={styles.asteriskError}>*</span></label>
                                <TextField variant="filled" value={name || ''} className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'name' }} type='text' fullWidth placeholder="Name" onChange={updateReleaseDetails} helperText={getErrorMessage('name')} />
                            </div>
                            <div className='col-lg-6 col-md-6 col-12 mb-4 pl-md-0'>
                                <label className={`txt-md ${styles.txtColor}`}>Type <span className={styles.asteriskError}>*</span></label>
                                <Select
                                    displayEmpty
                                    variant='filled'
                                    disableUnderline
                                    className='w-100 mt-2 txt-md'
                                    inputProps={{ name: 'type' }}
                                    onChange={updateReleaseDetails}
                                    defaultValue={type}
                                    helperText={getErrorMessage('type')}
                                >
                                    <MenuItem className='txt-md' value={'release'}>Release</MenuItem>
                                    <MenuItem className='txt-md' value={'patch'}>Patch</MenuItem>
                                </Select>
                                <FormHelperText>{getErrorMessage('type')}</FormHelperText>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12 mb-4 pl-md-0'>
                                <label className={`txt-md ${styles.txtColor}`}>Device Type  <span className={styles.asteriskError}>*</span></label>
                                <Select
                                    displayEmpty
                                    variant='filled'
                                    disableUnderline
                                    className='w-100 mt-2 txt-md'
                                    inputProps={{ name: 'device_type' }}
                                    onChange={updateReleaseDetails}
                                    defaultValue={device_type}
                                    helperText={getErrorMessage('device_type')}
                                >
                                    <MenuItem className='txt-md' value={'Hardware'}>Hardware</MenuItem>
                                    <MenuItem className='txt-md' value={'Software'}>Software</MenuItem>
                                </Select>
                                <FormHelperText>{getErrorMessage('device_type')}</FormHelperText>
                            </div>
                            {type === 'release' &&
                                <div className='col-lg-6 col-md-6 col-12 mb-4 pl-md-0'>
                                    <label className={`txt-md ${styles.txtColor}`}>Release Version <span className={styles.asteriskError}>*</span></label>
                                    {<TextField variant="filled" disabled={true} value={version} className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'version' }} type='text' fullWidth placeholder="Release Version" />}

                                </div>

                            }
                            {type === 'patch' &&
                                <div className='col-lg-6 col-md-6 col-12 mb-4 pl-md-0'>
                                    <label className={`txt-md ${styles.txtColor}`}>Release Version <span className={styles.asteriskError}>*</span></label>
                                    <Select
                                        displayEmpty
                                        variant='filled'
                                        disableUnderline
                                        className='w-100 mt-2 txt-md'
                                        inputProps={{ name: 'version' }}
                                        onChange={updateReleaseDetails}
                                        defaultValue={""}
                                        helperText={getErrorMessage('version')}
                                    >
                                        <MenuItem className='txt-md' value=""> Select release version </MenuItem>
                                        <MenuItem className='txt-md' value={Number(3).toFixed(1)}>3.0</MenuItem>
                                        {versionList.map((el, ind) => <MenuItem className='txt-md' value={el}>{el}</MenuItem>)}
                                    </Select>
                                    <FormHelperText>{getErrorMessage('version')}</FormHelperText>
                                </div>}
                            {releaseDetails.type === 'patch' && patch_version &&
                                <div className='col-lg-6 col-md-6 col-12 mb-4 pl-md-0'>
                                    <label className={`txt-md ${styles.txtColor}`}>Patch Version <span className={styles.asteriskError}>*</span></label>
                                    <TextField disabled={true} variant="filled" value={patch_version} className={'withoutLabel mt-2'} inputProps={{ className: 'txt-md', name: 'patch_version' }} type='text' fullWidth placeholder="Patch Version" onChange={updateReleaseDetails} helperText={getErrorMessage('patch_version')} />
                                </div>}

                            <div className='col-lg-6 col-md-6 col-12 mr-auto col-8 mb-4 pl-md-0 pt-1'>
                                <div className='d-flex flex column justify-content-center ' >
                                    <div className={`d-flex flex-column justify-content-center ${styles.addImg}`}>
                                        <label className={`txt-md ${styles.txtColor}`}>Files <span className={styles.asteriskError}>*</span></label>
                                        <Select
                                            displayEmpty
                                            variant='filled'
                                            disableUnderline
                                            className='w-100 mt-2 txt-md '
                                            inputProps={{ name: 'file_url' }}
                                            onChange={updateReleaseDetails}
                                            helperText={getErrorMessage('file_url')}
                                        >
                                            {type === "release" && releaseList.map((el, ind) => <MenuItem value={el.file}>{el.filename}</MenuItem>)}
                                            {type === "release" && !releaseList.length && <MenuItem value={''}>No file to select</MenuItem>}
                                            {type === "patch" && patchList.map((el, ind) => <MenuItem value={el.file}>{el.filename}</MenuItem>)}
                                            {type === "patch" && !patchList.length && <MenuItem value={''}>No file to select</MenuItem>}
                                        </Select>
                                        <FormHelperText>{getErrorMessage('file_url')}</FormHelperText>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 mb-4 px-md-0'>
                                <label className={`txt-md mb-2 d-inline-block ${styles.txtColor}`}>Release Details <span className={styles.asteriskError}>*</span></label>
                                <textarea className={`${styles.textArea}`} value={description || ''} name='description' placeholder='Release Contents Will be Placed here' onChange={updateReleaseDetails}></textarea>
                                {getErrorMessage('description') && <FormHelperText>{getErrorMessage('description')}</FormHelperText>}
                            </div>
                        </div>

                        <div className='text-center mt-3'>
                            <Button variant="outlined" color="primary" className='px-4 mr-3'><span className='txt-primary' onClick={() => history.push('/release')}>Cancel</span></Button>

                            <Button variant="contained" color="primary" className='px-4' onClick={saveDetails}><img src={submitIcon} className='mr-2' /><span className='txt-white txt-light'>Create Release</span></Button>
                        </div>

                    </div>
                </div>
                <Snackbar open={showSnackBar} message={snackbarContent?.message || ''} className={snackbarContent?.class || ''} autoHideDuration={4000} closeSnackBar={setShowSnackBar} />
            </div>

        </>
    )
}

export default CreateRelease