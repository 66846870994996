import React, { useState, useContext, useEffect } from "react";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ReactComponent as CheckedIcon } from 'assets/images/icon/check.svg'
import { ReactComponent as UnCheckedIcon } from 'assets/images/icon/uncheck.svg'
import AuthServices from 'api/services/auth-services';
import { GlobalContext, actions } from 'context';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { format, isValid } from "date-fns";
import email from 'assets/images/icon/email-white.svg'
import download from 'assets/images/icon/download.svg'
import { Snackbar } from 'components';
import Header from "app/header/header";

function Report() {

  const [filterType, setFilterType] = useState('all');
  const { dispatch } = useContext(GlobalContext);

  const [data, setData] = useState([])
  const baseUrl = process.env.REACT_APP_OTA_API_URL

  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackbarContent, setSnackBarContent] = useState({ message: '', class: 'error' });

  const handleChange = (event) => {
    setFilterType(event.target.value);
  };

  useEffect(() => {
    getDetail()
  }, [filterType])

  const getDetail = async () => {
    storeHandler(actions.SHOW_LOADER, true)
    try {
      const { reports } = await AuthServices.getReport(filterType)
      setData([...reports])
      storeHandler(actions.SHOW_LOADER, false)
    } catch (err) {
      storeHandler(actions.SHOW_LOADER, false)
    }
  }

  const sendEmail = async () => {
    storeHandler(actions.SHOW_LOADER, true)
    try {
      const { message } = await AuthServices.sendEmail()
      const data = { message: 'Email sent successfully !', class: 'success' }
      setShowSnackBar(true)
      setSnackBarContent({ ...data })
      storeHandler(actions.SHOW_LOADER, false)
    } catch (err) {
      const data = { message: 'Error while sending email. Please try after sometime', class: 'error' }
      setShowSnackBar(true)
      setSnackBarContent({ ...data })
      storeHandler(actions.SHOW_LOADER, false)
    }
  }
  const storeHandler = (type, payload) => dispatch({ type, payload })

  return (
    <>
      <Header searchBar={false}/>
      <div className="d-flex justify-content-end">
        <a href={`${baseUrl}/reports/download/pdf`} target='_blank' className="report-btn mr-3">Download <span className="ml-2 icon-wrapper"><img src={download} /></span></a>
        <div onClick={sendEmail} className="report-btn">Email <span className="ml-2 icon-wrapper"><img src={email} /></span></div>
      </div>
      <div className="d-flex body_container flex-grow-1 pb-5 flex-wrap">
        <div className="reports-filter-container pr-3 border-right col-lg-2 col-3">
          <span className="txt-lg txt-grey-lt mt-4 mb-3 d-block">Filter By</span>
          {/* <FormGroup className="txt-grey-lt">
            <FormControlLabel control={<Checkbox defaultChecked />} label="Group" />
            <FormControlLabel control={<Checkbox defaultChecked />} label="Release Date" />
            <FormControlLabel control={<Checkbox defaultChecked />} label="Deploy Date" />
            <FormControlLabel control={<Checkbox defaultChecked />} label="Patch" />
            <FormControlLabel control={<Checkbox defaultChecked />} label="RPI" />

          </FormGroup> */}

          <RadioGroup name="reports" value={filterType} onChange={handleChange}>
            <FormControlLabel value="all" className="mb-2" control={<Radio icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} />} label="All" />
            <FormControlLabel value="group" className="mb-2" control={<Radio icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} />} label="Group" />
            <FormControlLabel value="release" className="mb-2" control={<Radio icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} />} label="Release Date" />
            <FormControlLabel value="deployment" className="mb-2" control={<Radio icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} />} label="Deploy Date" />
            <FormControlLabel value="patch" className="mb-2" control={<Radio icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} />} label="Patch" />
            <FormControlLabel value="rpi" className="mb-2" control={<Radio icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} />} label="RPI" />
            <FormControlLabel value="failure" className="mb-2" control={<Radio icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} />} label="Failure" />
            <FormControlLabel value="success" className="mb-2" control={<Radio icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} />} label="Success" />
            {/* <FormControlLabel value="category" className="mb-2" control={<Radio icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} />} label="Category" />
            <FormControlLabel value="plan" className="mb-2" control={<Radio icon={<UnCheckedIcon />} checkedIcon={<CheckedIcon />} />} label="Plan" /> */}
          </RadioGroup>
        </div>
        <div className="px-5 py-4 txt-grey-lt col-9 col-lg-10">
          {data && data.length >0? data.map((item, i) => (<p className="mb-1">
            {item.message} {isValid(new Date(item?.datetime)) ? format(new Date(item?.datetime), 'MM-dd-yyyy hh:mm:ss a') : '-'}
          </p>)):<p className="text-center mt-5 mr-5  pt-5 ">No data found</p>}

        </div>
        <Snackbar open={showSnackBar} message={snackbarContent?.message || ''} className={snackbarContent?.class || ''} autoHideDuration={4000} closeSnackBar={setShowSnackBar} />

      </div>
    </>
  );
}

export default Report;
