import axios from 'axios';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_OTA_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
});

const onSuccess = (response) => Promise.resolve(response);
const onError = async ({ response }) => {
  const { data } = response || {};
  const { message } = data || {};
  if (message === 'Token has expired' || response.status === 422 || response.status === 401 || message === 'Master ID not found') {
    localStorage.clear()
    window.location.reload();
  }
  return Promise.reject(response)
};

httpClient.interceptors.request.use((req) => {
  if (req.url.indexOf('/release/create') > -1) {
    req.headers['Content-Type'] = 'multipart/form-data'
  }
  if (req.url === '/refresh/token') {
    req.headers['Authorization'] = `Bearer ${localStorage.getItem('refreshToken')}`
  } else {
    req.headers['Authorization'] = `Bearer ${localStorage.getItem('authToken')}`
  }
  return req
}
);
httpClient.interceptors.response.use(onSuccess, onError);

export default httpClient;
