import React, {useState} from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { subYears, isValid, parseISO } from 'date-fns';
import { ReactComponent as CalendarIcon } from 'assets/images/icon/calender.svg';
import {
  MuiPickersUtilsProvider,
  DatePicker as MuiDatePicker,
} from '@material-ui/pickers';
import { TextField } from '@material-ui/core';
import 'date-fns';
import moment from 'moment/moment';


const textFieldComponent = (TextFieldProps) => (
  <TextField
    {...TextFieldProps}
    variant="filled"
    placeholder="Select Date"
    disableUnderline
    className='withoutLabel'
    InputProps={{
      endAdornment: <CalendarIcon className="calendarIcon" />,
      disableUnderline: true
    }}
  />
);

const DatePicker = (props) => {
  const {value} = props
  const [isOpen, setIsOpen] = useState(null);
  const dateObj = isValid(new Date(`${value} 00:00`)) ? new Date(`${value} 00:00`) : value

  return(
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiDatePicker
        //   clearable={props.clearable === false ? false : true}
        {...props}
        format="MMM dd, yyyy"
        margin="dense"
        fullWidth
        autoOk
        okLabel={null}
        disablePast
        TextFieldComponent={textFieldComponent}
        onOpen={() => setIsOpen(!!props?.senior ? subYears(new Date(), 40) : new Date())}
        onClose={() => setIsOpen(null)}
        // maxDate={moment()}
        value={dateObj || isOpen}

      />
    </MuiPickersUtilsProvider>
  )
};

export default DatePicker;
