import { TextField as MuiTextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { IconButton } from '@material-ui/core';
import showIcon from 'assets/images/icon/eye-icon.svg';
import hideIcon from 'assets/images/icon/eye-close.svg';
import { useState } from 'react';

const TextField = (props) => {
    const { passwordfield, type, withoutAdornment, alphanumeric, max, chatAdornment } = props;
    const [showPassword, setshowPassword] = useState(false);
    const showHidePassword = () => {
        setshowPassword(!showPassword);
    };
    const onKeyPress = (e) => {
        if (type === 'number') {
            var charCode = typeof e.which == 'undefined' ? e.keyCode : e.which;
            var charStr = String.fromCharCode(charCode);
            if (!charStr.match(/^[0-9.]+$/)) e.preventDefault();
        }

    };
    const onKeyDown = (event) => {
        if (event.target.type === 'number' && event.which === 69) {
            event.preventDefault();
        }
        if (alphanumeric) {
            const regex = /^[a-zA-Z ]+$/;
            // const regex = /^[a-zA-Z0-9 .-]+$/;

            if (regex.test(event.key)) {
                return
            }
            event.preventDefault()
        }
        if (max && Number(event.target.value) > Number(max)) {
            event.target.value = event.target.value.split('')[0]
            event.preventDefault()
        }

    };

    const validatePassword = (event) => {
        if (event.which === 32) {
            event.preventDefault();
        }
    };

    if (passwordfield && !withoutAdornment) {
        let passwordFieldProps = { ...props };
        delete passwordFieldProps.passwordfield;
        return (
            <MuiTextField
                {...passwordFieldProps}
                onKeyDown={validatePassword}
                inputProps={{
                    type: showPassword ? 'text' : 'password',
                    autoComplete: 'new-password',
                }}
                InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton disableRipple={true} onClick={showHidePassword}>
                                <img src={!showPassword ? showIcon : hideIcon} alt="show" width={30} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    if (passwordfield && withoutAdornment) {

        let passwordFieldProps = { ...props };
        delete passwordFieldProps.passwordfield;
        delete passwordFieldProps.withoutAdornment
        return (
            <MuiTextField
                {...passwordFieldProps}
                onKeyDown={validatePassword}
                inputProps={{
                    type: 'password',
                    autoComplete: 'new-password',
                }}
                InputProps={{
                    disableUnderline: true,
                }}
            />
        );
    }

    if (chatAdornment) {
        const { InputProps, onChange } = props;
        return <MuiTextField
            {...props}
            onChange={onChange}
            InputProps={InputProps}
        />
    }


    return (
        <MuiTextField
            {...props}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            InputProps={{ disableUnderline: true }}
        />
    );
};

export default TextField;
