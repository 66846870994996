import { CircularProgress, Step, StepConnector, StepLabel, Stepper, makeStyles, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { ReactComponent as Alert_circle_20 } from 'assets/images/icon/Alert_circle_20.svg'
import { ReactComponent as Check_circle_20 } from 'assets/images/icon/Check_circle_20.svg'
import { ReactComponent as Download_32 } from 'assets/images/icon/Download_32.svg'
import { ReactComponent as Installing_32 } from 'assets/images/icon/Installing_32.svg'
import { ReactComponent as Rebooting_32 } from 'assets/images/icon/Rebooting_32.svg'
import { ReactComponent as Nhub } from 'assets/images/icon/Nhub.svg' 

import styles from './deploy-steps.module.scss'
import { Box } from 'components';

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
        '& $line': {
            color: "red"
        },
    },
    active: {
        '& $line': {
            backgroundColor:
                '#00B89F',
        },
    },
    completed: {
        '& $line': {
            backgroundColor:
                '#00B89F',
        },
    },
    line: {
        height: 5,
        border: 0,
        backgroundColor: '#646464',
        borderRadius: 1,
    },
})(StepConnector);

function getSteps() {
    return ['Download', 'Installing', 'Rebooting', "Finished"];
}


const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#646464',
        zIndex: 1,
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundColor: '#272e36',
    },
    completed: {
        backgroundColor: '#272e36',
    },
    error: {
        backgroundColor: '#272e36',
    },

});


function ColorlibStepIcon(props, percent) {
    const classes = useColorlibStepIconStyles();
    const { active, completed, error } = props;

    const icons = {
        1: <Download_32 className={`${styles['svg-size']} ${(active || completed) && styles.green} ${error && styles.error} `} />,
        2: <Installing_32 className={`${styles['svg-size']} ${(active || completed) && styles.green} ${error && styles.error} `} />,
        3: <Rebooting_32 className={`${styles['svg-size']} ${(active || completed) && styles.green} ${error && styles.error} `} />,
        4: <Nhub className={`${styles['svg-size']} ${(active || completed) && styles.green} ${error && styles.error} `} />
    };

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }} className={`${classes.root} ${active && classes.active} ${completed && classes.completed} ${error && classes.error} `}>
            {(active || completed || error) && <CircularProgress style={{ position: 'absolute', color: '#3b3b3b' }} thickness={2} variant="determinate" value={100} size={'100%'} />}
            {completed && <CircularProgress thickness={2} variant="determinate" value={100} size={'100%'} />}
            {(active && !error) && <CircularProgress style={{ transform: "rotate(90deg)" }} thickness={2} variant="determinate" value={percent} size={'100%'} />}
            <Box
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {icons[String(props.icon)]}
            </Box>
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

}));


function DeploySteps({ deploy_timeline_status }) {
    const { failure, status } = deploy_timeline_status || { status: 1, failure: 0 }
    const [activeStep, setActiveStep] = useState(0)
    const [failedStep, setFailedStep] = useState('')
    const [activePercent, setActivePercent] = useState(0)
    const classes = useStyles();
    const steps = getSteps();

    useEffect(() => {
        setActiveStep(status)
        if (failure) setFailedStep(status)
        if(status === 3) setActivePercent(100)
    }, [])

    return (
        <div className={`${classes.root}`}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map((label, index) => {
                    const labelProps = {};
                    if (index === failedStep) {
                        labelProps.error = true;
                    }

                    return (
                        <Step key={label}>
                            <StepLabel {...labelProps} StepIconComponent={(props) => ColorlibStepIcon(props, activePercent)}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    {label}
                                    {(index < status || (status === 3 && !failure) ) && <Check_circle_20 />}
                                    {failure && index === status && <Alert_circle_20 />}
                                </div>
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </div>
    );
}

export default DeploySteps;