const backEndValidations = {
    'Invalid Mobile Number!': { mobile: 'Invalid Mobile Number', forgetPassMobile: 'Invalid mobile number' },
    'Incorrect Password!': { password: 'Incorrect Password' },
    'member with this mobile no not found': { forgetPassMobile: 'Invalid mobile number' },
    'Incorrect OTP!': { otp: 'Incorrect OTP' }
}

const errorMessages = {
    mobile: 'Enter Mobile Number',
    password: 'Enter Password',
    otp: 'Icorrect OTP'
}

const getErrorMessage = (isInvalid, field, value, fromError) => {
    if (!isInvalid) {
        return
    }
    return !value ? errorMessages[field] : fromError && backEndValidations[fromError]?.[field]
}


export { errorMessages, getErrorMessage };